import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteTree from 'react-infinite-tree';
import cx from 'classnames';
import { hash } from 'immutable';
import debounce from 'lodash/debounce';

import Loader from './utils/Loader';
import sortBy from 'lodash/sortBy';
import { getLocaleFromLanguageCode } from '../intl-helpers';

function sortTree(leafs, locale) {
    return sortBy(leafs, (leaf) => {
        if (leaf.children && leaf.children.length > 0) {
            leaf.children = sortTree(leaf.children, locale);
        }

        return leaf.name;

        // FIXME: tradurre
        // return leaf.name ? leaf.name[locale] : 'Non classificato';
    });
}

function searchTree(tree, nodesProp, prop, value) {
    let i,
        f = null;
    if (Array.isArray(tree)) {
        for (i = 0; i < tree.length; i++) {
            f = searchTree(tree[i], nodesProp, prop, value);
            if (f) {
                return f;
            }
        }
    } else if (typeof tree === 'object') {
        if (tree[prop] !== undefined && tree[prop] === value) {
            return tree;
        }
    }
    if (tree[nodesProp] !== undefined && tree[nodesProp].length > 0) {
        return searchTree(tree[nodesProp], nodesProp, prop, value);
    } else {
        return null;
    }
}

class SectorsTree extends Component {
    tree = null;

    constructor(props) {
        super(props);

        this.state = {
            filterValue: '',
            openNodes: 0,
        };
    }

    componentDidMount() {
        this.loadTreeFromProps();
    }

    componentDidUpdate(prevProps) {
        const hasHashChanged = hash(prevProps.data) !== hash(this.props.data);
        const hasFetchFinished = prevProps.isFetching === true && this.props.isFetching === false;
        const hasLanguageChange = prevProps.language !== this.props.language;

        // console.warn('hash', hasHashChanged);
        // console.warn('fetch', hasFetchFinished);

        // console.warn(prevProps.selectedItem, this.props.selectedItem);

        // Se é cambiata la struttura dell'albero (hash) o sono stati rifetchati i dati ricarico l'albero e mantengo eventuale nodo selezionato
        if (hasHashChanged || hasFetchFinished || hasLanguageChange) {
            this.loadTreeFromProps();
            this.mantainSelectedLeaf();

            // Nel caro riappliclo il filtro per la classe ETIM selezionata
            if (
                prevProps.selectedItem === this.props.selectedItem &&
                this.props.selectedItem !== null &&
                this.props.classificationType === 'etim'
            ) {
                this.filterSelectedEtimClass();
            }
            // altrimenti se è cambiato l'oggetto selezionato verifico se necessario chiudere altri nodi e mantengo comunque il nodo selezionato
        } else if (prevProps.selectedItem !== this.props.selectedItem) {
            // console.warn('!==');
            this.checkOpenBranches();
            this.mantainSelectedLeaf();
        }

        if (
            prevProps.selectedItem !== this.props.selectedItem &&
            this.props.classificationType === 'etim'
        ) {
            this.filterSelectedEtimClass();
        }

        if ((this.props.selectedItem && prevProps.selectedItem === null) || hasHashChanged) {
            setTimeout(() => {
                this.setState({
                    filterValue: '',
                });
            }, 0);
        }
    }

    checkOpenBranches() {
        if (this.tree) {
            const selectedNode = this.tree.getNodeById(this.props.selectedItem);

            if (selectedNode) {
                // Se il nodo selezionato non ha figli chiudo tutti gli altri eventuali nodi aperti, da richiesta del committente
                if (selectedNode.hasChildren() === false) {
                    const openNodes = this.tree.getOpenNodes();

                    // reverse per cercare di chiudere prima i nodi più profondi dell'albero
                    openNodes.reverse().forEach((n) => {
                        // verifico se il nodo esiste ancora nell'albero perchè chiudendo un nodo
                        // potrei chiudere anche uno dei figli che era aperto e darebbe errore
                        if (this.tree.nodes.indexOf(n) !== -1) {
                            this.tree.closeNode(n);
                        }
                    });
                }
            }
        }
    }

    localizeLeafsName(leaf) {
        return {
            ...leaf,
            name: this.getItemLabel(leaf.name, leaf.code),
            children: leaf.children.map((child) => {
                return this.localizeLeafsName(child);
            }),
        };
    }

    loadTreeFromProps() {
        // console.log(this.props.data.toJS());

        if (this.tree) {
            const localizedTree = this.props.data.toJS().map((item) => {
                return this.localizeLeafsName(item);
            });

            const sortedTree = sortTree(localizedTree, this.getLocale());
            // console.log(sortedTree);
            this.tree.loadData(sortedTree);
        }
    }

    mantainSelectedLeaf() {
        // console.warn(this.props);
        // console.warn(this.tree);

        if (this.tree) {
            // Ottengo il nodo selezionato da prop e quello nello stato interno dell'albero
            const node = this.tree.getNodeById(this.props.selectedItem);
            const selectedNode = this.tree.getSelectedNode();

            // console.warn(node === selectedNode);

            // Se sono differenti trovo i parent del nodo selezionato e li apro, scrollando sul nodo selezionato
            if (node && node !== selectedNode) {
                const parent = node.getParent();

                // let nodeToScroll = null;

                if (parent.id !== null) {
                    // nodeToScroll = parent;
                    this.tree.openNode(parent);

                    const grandPa = parent.getParent();

                    if (grandPa.id !== null) {
                        this.tree.openNode(grandPa);
                        // nodeToScroll = grandPa;
                    }

                    this.tree.scrollToNode(selectedNode);
                }
            }
        }
    }

    handleItemClick(node) {
        this.tree.selectNode(node);
        this.props.onItemClick(node.id, node.state.depth);
    }

    getLocale() {
        return getLocaleFromLanguageCode(this.props.language);
    }

    getItemLabel(name, code) {
        if (name && name[this.getLocale()]) {
            return name[this.getLocale()];
        }

        if (code) {
            return `[${code}]`;
        }

        return '[n.d.]';
    }

    filterSelectedEtimClass = () => {
        if (this.props.selectedItem) {
            const filterOptions = {
                caseSensitive: true,
                exactMatch: true,
                includeAncestors: true,
                includeDescendants: true,
            };

            const match = searchTree(
                this.props.data.toJS(),
                'children',
                'id',
                this.props.selectedItem
            );

            if (match) {
                const languageCode = getLocaleFromLanguageCode(this.props.language);

                this.tree.filter(match.name[languageCode], filterOptions);
            }
        } else {
            const filterOptions = {
                caseSensitive: false,
                exactMatch: false,
                includeAncestors: true,
                includeDescendants: true,
            };

            this.tree.filter('', filterOptions);
        }
    };

    openNodeToRoot = (node) => {
        // console.log('OPEN', node);
        if (!node.id) {
            return;
        }

        this.tree.openNode(node);

        const parent = node.getParent();

        if (parent) {
            this.openNodeToRoot(parent);
        }
    };

    searchNodes = (nodes) => {
        // console.log(this.tree);
        // console.log('SEARCHING');
        nodes.forEach((node) => {
            // console.log(node.name);
            if (node.name.toLowerCase().match(new RegExp(`${this.state.filterValue}`, 'g'))) {
                // console.log('OPEN ' + node.name);

                this.openNodeToRoot(node);
            }

            if (node.getChildren().length > 0) {
                this.searchNodes(node.getChildren());
            }
        });
    };

    filterTree = () => {
        // console.log('FILTER TREE');

        const filterOptions = {
            caseSensitive: false,
            exactMatch: false,
            includeAncestors: true,
            includeDescendants: true,
        };

        this.tree.filter(this.state.filterValue, filterOptions);

        if (this.tree && this.state.filterValue !== '') {
            // console.log(this.tree);

            this.searchNodes(this.tree.state.rootNode.children);

            // this.tree.state.rootNode.children.forEach(node => {
            //     if (node.name.toLowerCase().match(new RegExp(`${this.state.filterValue}`, 'g'))) {
            //         this.tree.openNode(node);
            //     }

            //     node.getChildren().forEach(subNode => {
            //         if (
            //             subNode.name
            //                 .toLowerCase()
            //                 .match(new RegExp(`${this.state.filterValue}`, 'g'))
            //         ) {
            //             // this.tree.openNode(subNode);
            //             // console.log('Sub: ' + subNode.name.toLowerCase());
            //             if (subNode.getParent()) {
            //                 this.tree.openNode(subNode.getParent());
            //             }
            //         }

            //         subNode.getChildren().forEach(subsubNode => {
            //             // console.log(subNode.name + ' -> ' + subsubNode.name);
            //             if (
            //                 subsubNode.name
            //                     .toLowerCase()
            //                     .match(new RegExp(`${this.state.filterValue}`, 'g'))
            //             ) {
            //                 // console.log('Subsub: ' + subsubNode.name.toLowerCase());
            //                 this.tree.openNode(subsubNode.getParent().getParent());
            //                 this.tree.openNode(subsubNode.getParent());
            //             }
            //         });
            //     });
            // });
        }
    };

    debouncedFilterTree = debounce(this.filterTree, 500);

    render() {
        if (this.props.isFetching) {
            return <Loader />;
        }

        // console.log(this.props);

        return (
            <>
                <div className="input-group" style={{ marginBottom: '10px' }}>
                    <input
                        type="text"
                        className="form-input"
                        // placeholder={i18n._('filter:by')}
                        onChange={(e) => {
                            this.setState({ filterValue: e.target.value });

                            this.debouncedFilterTree();
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setTimeout(() => {
                                    this.filterTree();
                                }, 0);
                            }
                        }}
                        value={this.state.filterValue}
                        disabled={
                            this.props.selectedItem && this.props.classificationType === 'etim'
                        }
                    />

                    <button
                        type="button"
                        className="btn input-group-btn"
                        onClick={() => {
                            this.setState({ filterValue: '' });

                            setTimeout(() => {
                                this.filterTree();
                            }, 0);
                        }}
                        disabled={this.state.filterValue === ''}
                    >
                        <i className="icon icon-cross"></i>
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary input-group-btn"
                        onClick={this.filterTree}
                        disabled={
                            this.props.selectedItem && this.props.classificationType === 'etim'
                        }
                    >
                        <i className="icon icon-search" />
                    </button>
                </div>
                <InfiniteTree
                    ref={(node) => (this.tree = node ? node.tree : null)}
                    autoOpen={false}
                    selectable={true}
                    height={this.state.openNodes * 24 > 120 ? this.state.openNodes * 24 + 64 : 120}
                    width="100%"
                    rowHeight={24}
                    onContentDidUpdate={() => {
                        if (this.tree) {
                            // console.log(this.tree);
                            this.setState({
                                openNodes: this.tree.nodes.length,
                            });
                        }
                    }}
                    // onSelectNode={node => console.warn(node)}
                    // onClick={event => {
                    // console.warn(event);
                    // const target = event.target;
                    // console.log('click:', target);
                    // }}
                    // onSelectNode={node => console.warn(node)}
                    rowRenderer={(rowProps) => {
                        const { tree, node } = rowProps;

                        const {
                            id,
                            name,
                            // code,
                            /*selected, count,*/
                            state /*children,  props = {}*/,
                        } = node;
                        const { depth, open /*, path, total*/ } = state;
                        const { options } = tree;
                        const more = node.hasChildren();
                        // const isClass = depth > 0;

                        const isSelected = this.props.selectedItem === id;

                        return (
                            <div
                                className={cx('infinite-tree-item', {
                                    'bg-secondary': isSelected,
                                })}
                                data-id={id}
                            >
                                <div
                                    className="infinite-tree-node"
                                    style={{ marginLeft: depth * 18 }}
                                >
                                    {more && open && (
                                        <span
                                            className={cx(options.togglerClass, 'c-hand')}
                                            onClick={() => tree.closeNode(node)}
                                        >
                                            <i className="icon icon-small icon-arrow-down mr-1 text-primary" />
                                        </span>
                                    )}
                                    {more && !open && (
                                        <span
                                            className={cx(
                                                options.togglerClass,
                                                'c-hand',
                                                'infinite-tree-closed'
                                            )}
                                            onClick={() => tree.openNode(node)}
                                        >
                                            <i className="icon icon-small icon-plus mr-1 text-primary" />
                                        </span>
                                    )}
                                    {!more && (
                                        <span
                                            className={cx(
                                                options.togglerClass,
                                                'infinite-tree-closed'
                                            )}
                                        >
                                            <i className="icon icon-small icon-minus mr-1 text-primary" />
                                        </span>
                                    )}
                                    <span
                                        className={cx(
                                            'infinite-tree-title',
                                            'c-hand',
                                            'tooltip',
                                            'tooltip-wide',
                                            {
                                                'tooltip-bottom': state.depth < 2,
                                            }
                                        )}
                                        // data-tooltip={this.getItemLabel(name, code)}
                                        data-tooltip={name}
                                        onClick={() => this.handleItemClick(node)}
                                    >
                                        <span
                                            className={
                                                isSelected
                                                    ? 'infinite-tree-leaf text-bold'
                                                    : 'infinite-tree-leaf'
                                            }
                                        >
                                            {name}
                                        </span>
                                        {/*more && (
                                            <span className="text-gray">
                                                ({count})
                                            </span>
                                        )*/}
                                    </span>
                                </div>
                            </div>
                        );
                    }}
                />
            </>
        );
    }
}

SectorsTree.propTypes = {
    data: PropTypes.object,
    selectedItem: PropTypes.string,
    language: PropTypes.string,
    // onSelectClass: PropTypes.func,
    // onSelectGroup: PropTypes.func,
    onItemClick: PropTypes.func,
    sidebarHeight: PropTypes.number,
};

SectorsTree.defaultProps = {
    data: [],
};

export default SectorsTree;
