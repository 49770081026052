import { Record, Map, OrderedSet } from 'immutable';

import { actionTypes } from '../actions/usersActions';

const UserRecord = Record({
    id: null,
    nome: null,
    cognome: null,
    email: null,
    enabled: null,
    locked: null,
    locale: null,
    ruolo: null,
    titolo: null,
    group_id: null,
    nome_provincia: null,
    sigla_provincia: null,
    attivita: null,
    ragione_sociale: null,
    created_at: null,
    enabled_at: null,
    locked_at: null,
    logged_at: null,
    preferences: []
});

const FiltersRecord = Record({
    q: '',
    locked: '',
    enabled: '',
    provincia: null,
    attivita: null,
    group_id: null
});

const StateRecord = Record({
    currentPage: 0,
    errorMessage: null,
    isFetching: false,
    itemsById: Map({}),
    itemsId: OrderedSet([]),
    pages: 0,
    per_page: 10,
    total: 0,
    sortBy: null,
    sortDirection: 'asc',
    filters: FiltersRecord()
});

export const DEFAULT_STATE = StateRecord();

function fetchStart(state, action) {
    return state.set('isFetching', true);
}

function fetchSuccess(state, action) {
    const {
        payload: { results, meta }
    } = action;

    let items = Map({});
    let ids = OrderedSet([]);

    results.forEach(item => {
        // TODO: effettuare alcune operazioni sui dati? per esempio tornare l'immagine in maniera più easy, invece di ripetere la logica nei vari componenti?
        items = items.set(item.id, UserRecord(item));
        ids = ids.add(item.id);
    });

    return state.merge({
        isFetching: false,
        total: meta.total,
        pages: Math.ceil(meta.total / state.per_page),
        itemsById: items,
        itemsId: ids
    });
}

function fetchFail(state, action) {
    return state.set('isFetching', false);
}

function enableUser(state, action) {
    return state.updateIn(['itemsById', action.payload], user => {
        return user.set('enabled', true);
    });
}

function deleteUser(state, action) {
    const newTotal = state.total - 1;

    return state
        .updateIn(['itemsId'], users => {
            return users.delete(action.payload);
        })
        .merge({
            total: newTotal,
            pages: Math.ceil(newTotal / state.per_page)
        });
}

function toggleLockUser(state, action) {
    return state.updateIn(['itemsById', action.payload], user => {
        return user.set('locked', !user.locked);
    });
}

function setFilter(state, action) {
    const updatedState = state.updateIn(['filters'], filters => {
        return filters.set(action.payload.key, action.payload.value);
    });

    if (updatedState.currentPage === 0) {
        return updatedState;
    }

    return updatedState.set('currentPage', 0);
}

function resetFilters(state) {
    return state.merge({
        currentPage: 0,
        filters: FiltersRecord()
    });
}

function toggleDirection(current) {
    if (current === 'asc') {
        return 'desc';
    }

    return 'asc';
}

function sortUsers(state, action) {
    const attr = action.payload;
    const previousSort = state.get('sortBy');

    return state.merge({
        sortBy: attr,
        sortDirection: attr === previousSort ? toggleDirection(state.get('sortDirection')) : 'asc'
    });
}

function changePage(state, action) {
    return state.set('currentPage', action.payload);
}

function updateUserData(state, action) {
    const { id, data } = action.payload;

    return state.updateIn(['itemsById', id], user => user.merge(data));
}

const handlers = {
    [actionTypes.FETCH_USERS_START]: fetchStart,
    [actionTypes.FETCH_USERS_SUCCESS]: fetchSuccess,
    [actionTypes.FETCH_USERS_FAIL]: fetchFail,
    [actionTypes.ENABLE_USER]: enableUser,
    [actionTypes.DELETE_USER]: deleteUser,
    [actionTypes.TOGGLE_LOCK_USER]: toggleLockUser,
    [actionTypes.SET_USERS_FILTER]: setFilter,
    [actionTypes.RESET_USERS_FILTERS]: resetFilters,
    [actionTypes.SORT_USERS_BY]: sortUsers,
    [actionTypes.CHANGE_USERS_PAGE]: changePage,
    [actionTypes.UPDATE_USER_DATA]: updateUserData
};

export default function usersReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

export const selectors = {
    getCurrentPage(state) {
        return state.users.currentPage;
    },
    getPerPage(state) {
        return state.users.per_page;
    },
    getError(state) {
        return state.users.errorMessage;
    },
    getUser(state, id) {
        return state.users.itemsById[id];
    },
    getUsers(state) {
        return state.users.itemsId.map(id => {
            return state.users.itemsById.get(id);
        });
    },
    getUsersTotal(state) {
        return state.users.total;
    },
    getPagesTotal(state) {
        return state.users.pages;
    },
    getSorting(state) {
        return {
            sortBy: state.users.sortBy,
            sortDirection: state.users.sortDirection
        };
    },
    hasError(state) {
        return state.users.errorMessage !== null;
    },
    getIsFetching(state) {
        return state.users.isFetching;
    },
    getFilters(state) {
        return state.users.filters;
    }
};
