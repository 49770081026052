import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Trans, i18nMark, withI18n } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Link, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import get from 'lodash/get';

import Loader from './utils/Loader';
import MatchWhenAuthorized from './routing/MatchWhenAuthorized';
import InputField from './form/InputField';
import SelectProvinceField from './form/SelectProvinceField';
import SelectAttivitaField from './form/SelectAttivitaField';
import SelectTitoliField from './form/SelectTitoliField';
import SelectLanguageField from './form/SelectLanguageField';
import SubmitButton from './form/SubmitButton';
import ChangePasswordModal from './ChangePasswordModal';
// import BlockLink from './BlockLink';
import { selectors } from '../reducers/userReducer';
import { updateUser } from '../actions/userActions';
import { changeLanguage, changeDefaultTableView } from '../actions/appActions';
import { getUtente, updateUtente } from '../api';
import { getOptionLocaleFromCode, getShortCodeFromLocale } from '../intl-helpers';
import * as constants from '../constants';
import SelectField from './form/SelectField';

function validate(values) {
    const errors = {};

    const mandatoryFieldError = i18nMark('validation:error:mandatory:field');

    if (!values.nome) {
        errors.nome = mandatoryFieldError;
    }

    if (!values.cognome) {
        errors.cognome = mandatoryFieldError;
    }

    if (!values.provincia) {
        errors.provincia = mandatoryFieldError;
    }

    if (!values.attivita) {
        errors.attivita = mandatoryFieldError;
    }

    return errors;
}

export class UserProfile extends Component {
    state = {
        isFetching: true,
        user: {},
        showModal: false,
    };

    componentDidMount() {
        getUtente(this.props.user.id).then((res) => {
            let user = pick(res.data, ['email', 'nome', 'cognome', 'ragione_sociale']);

            user.provincia = {
                value: res.data.sigla_provincia,
                label: res.data.nome_provincia,
            };

            user.attivita = {
                value: res.data.attivita,
                label: res.data.attivita,
            };

            user.titolo = res.data.titolo
                ? {
                      value: res.data.titolo,
                      label: res.data.titolo,
                  }
                : null;

            user.locale = res.data.locale ? getOptionLocaleFromCode(res.data.locale) : null;

            const vista = get(res.data, 'preferences.vista', constants.VIEW_TYPE_COMPACT);

            user.preferences_vista = {
                value: vista,
                label: this.props.i18n._(`view:${vista}`),
            };

            this.setState({
                isFetching: false,
                user: user,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isFetching === false && prevState.isFetching === true) {
            setTimeout(() => {
                this.firstInput.focus();
            }, 0);
        }
    }

    onSubmit = async (data) => {
        const { i18n, onChangeLanguage, onChangeDefaultTableView, user } = this.props;
        // TODO: spostare tutta questa logica in una saga?

        let payload = {
            ...omit(data, ['provincia', 'titolo', 'attivita', 'locale', 'preferences_vista']),
            sigla_provincia: data.provincia.value,
            nome_provincia: data.provincia.label,
            attivita: data.attivita.value,
            locale: data.locale ? data.locale.value : 'it',
            preferences: {
                vista: data.preferences_vista.value,
            },
        };

        if (data.titolo) {
            payload.titolo = data.titolo.value;
        }

        let res = null;

        try {
            res = await updateUtente(user.id, payload);

            onChangeLanguage(getShortCodeFromLocale(payload.locale));
            onChangeDefaultTableView(payload.preferences.vista);
        } catch (err) {
            // console.error(err);
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                // console.log(err.response.data);
                // console.log(err.response.status);
                if (err.response.status === 412) {
                    errorMessage = i18n._('fill:required:fields');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage,
            };
        }

        this.props.dispatch(updateUser(pick(res.data, ['nome', 'cognome', 'titolo', 'locale'])));

        setTimeout(() => {
            toast(i18n._('update:profile:success'), {
                position: 'bottom-right',
                type: toast.TYPE.SUCCESS,
            });
        }, 500);
    };

    handleShowModal = () => {
        this.setState({
            showModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    };

    getInitialValues() {
        return this.state.user;
    }

    render() {
        const { isFetching, showModal } = this.state;
        const { i18n } = this.props;

        if (isFetching) {
            return <Loader height={100} />;
        }

        return (
            <div className="container grid-lg">
                <Fragment>
                    {showModal && (
                        <ChangePasswordModal
                            onClose={this.closeModal}
                            userId={this.props.user.id}
                        />
                    )}
                    <Form
                        onSubmit={this.onSubmit}
                        validate={validate}
                        initialValues={this.getInitialValues()}
                        render={({
                            handleSubmit,
                            pristine,
                            hasValidationErrors,
                            submitError,
                            submitting,
                            errors,
                            touched,
                            submitSucceeded,
                            ...rest
                        }) => {
                            // console.warn(rest);

                            return (
                                <form
                                    ref={(form) => (this.form = form)}
                                    onSubmit={handleSubmit}
                                    className="form-horizontal"
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <Prompt
                                        when={pristine === false && submitSucceeded === false}
                                        message={i18n._('confirm:leave:unsaved:changes')}
                                    />
                                    <div className="columns">
                                        <div className="column col-12 text-center text-primary">
                                            <h3>
                                                <Trans id="Update your personal data" />
                                            </h3>
                                            <span
                                                className="btn btn-link lg:float-right lg:absolute lg:top-2 lg:right-0"
                                                onClick={this.handleShowModal}
                                            >
                                                <i className="icon icon-edit" />{' '}
                                                <Trans id="change:password" />
                                            </span>
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="titolo"
                                                component={SelectTitoliField}
                                                placeholder={i18nMark('title')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="nome"
                                                component={InputField}
                                                placeholder={i18nMark('name')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                                mandatory={true}
                                                forwardRef={(ref) => (this.firstInput = ref)}
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="cognome"
                                                component={InputField}
                                                placeholder={i18nMark('surname')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                                mandatory={true}
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <div className="form-group">
                                                <label className="col-3 col-sm-12">Email</label>
                                                <div className="col-9 col-sm-12">
                                                    <input
                                                        type="text"
                                                        className="form-input input-lg"
                                                        value={this.state.user.email}
                                                        readOnly={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="locale"
                                                component={SelectLanguageField}
                                                placeholder={i18nMark('language')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="ragione_sociale"
                                                component={InputField}
                                                placeholder={i18nMark('company')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="attivita"
                                                component={SelectAttivitaField}
                                                placeholder={i18nMark('activity')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                                mandatory={true}
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <Field
                                                name="provincia"
                                                component={SelectProvinceField}
                                                placeholder={i18nMark('province')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                                mandatory={true}
                                            />
                                        </div>
                                        <div className="column col-12 mb-2">
                                            <legend>
                                                <Trans id="preferences" />
                                            </legend>
                                            <Field
                                                name="preferences_vista"
                                                component={SelectField}
                                                placeholder={i18nMark('default:table:view')}
                                                className="form-input input-lg"
                                                layout="horizontal"
                                                options={[
                                                    {
                                                        value: constants.VIEW_TYPE_COMPACT,
                                                        label: i18n._('view:compact'),
                                                    },
                                                    {
                                                        value: constants.VIEW_TYPE_DETAILED,
                                                        label: i18n._('view:detailed'),
                                                    },
                                                    {
                                                        value: constants.VIEW_TYPE_BLOCK,
                                                        label: i18n._('view:block'),
                                                    },
                                                ]}
                                                mandatory={true}
                                                clearable={false}
                                            />
                                        </div>
                                    </div>
                                    <SubmitButton submitting={submitting} disabled={pristine}>
                                        <Trans id="confirm" />
                                    </SubmitButton>
                                    <div className="mt-2">
                                        <Link to="/app">
                                            <i className="icon icon-back" />{' '}
                                            <Trans id="back:to:article:list" />
                                        </Link>
                                    </div>
                                    {/* <div className="column col-4">
                                        Resetta password
                                    </div> */}
                                    {submitError && (
                                        <div className="bg-error mt-2 p-2">{submitError}</div>
                                    )}
                                </form>
                            );
                        }}
                    />
                </Fragment>
            </div>
        );
    }
}

const UserProfileWithI18n = withI18n()(UserProfile);

const AuthorizedProfile = MatchWhenAuthorized(UserProfileWithI18n);

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state),
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeLanguage: (langCode) => {
            dispatch(changeLanguage(langCode));
        },
        onChangeDefaultTableView: (type) => {
            dispatch(changeDefaultTableView(type));
        },
        dispatch,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedProfile);
