import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withI18n, Trans } from '@lingui/react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import without from 'lodash/without';
import get from 'lodash/get';

import PropertiesTreeWidget from './utils/PropertiesTreeWidget';
import { selectors } from '../reducers/appReducer';
import { selectors as userSelectors } from '../reducers/userReducer';
import { setTableColumns } from '../actions/appActions';
import { changeUserPreferences } from '../api';
import { getLocaleFromLanguageCode } from '../intl-helpers';

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export class CustomizeTablePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProps: props.tableColumns,
        };
    }

    handleChangeSelected = (data) => {
        // console.log(data);

        this.setState({
            selectedProps: data.selectedNodes,
        });
    };

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        // console.log(result);

        const reordered = reorder(
            this.state.selectedProps,
            result.source.index,
            result.destination.index
        );

        // console.log(reordered);

        this.setState({
            selectedProps: reordered,
        });
    };

    handleSave = async () => {
        const user = this.props.user.toJS();

        try {
            /*const res =*/ await changeUserPreferences(user.id, {
                ...user.preferences,
                tableColumns: this.state.selectedProps,
            });

            // console.log(res);

            this.props.dispatch(setTableColumns(this.state.selectedProps));

            toast('Preferenze salvate correttamente!', {
                type: toast.TYPE.SUCCESS,
            });

            // onChangeLanguage(getShortCodeFromLocale(payload.locale));
            // onChangeDefaultTableView(payload.preferences.vista);
        } catch (error) {
            // TODO: cosa fare in caso di errore?
        }
    };

    removeNode = (node) => {
        this.setState({
            selectedProps: without(this.state.selectedProps, node),
        });
    };

    getNodeLabel(node) {
        const langCode = getLocaleFromLanguageCode(this.props.i18n.language);

        if (this.props.productProperties[node]) {
            return get(this.props.productProperties[node], `label.${langCode}`, node);
        }

        return node;
    }

    render() {
        return (
            <div className="grid grid-cols-12 gap-2 mx-1">
                <div className="col-span-12 lg:col-span-6 lg:col-start-4 text-center text-primary">
                    <h2>Seleziona e ordina proprietà da mostrare in tabella</h2>
                </div>
                <div className="hidden lg:block col-span-12 lg:col-span-3 lg:col-start-4">
                    <PropertiesTreeWidget
                        selectOnlyLeaf={true}
                        selectedNodes={this.state.selectedProps}
                        onChange={(properties) => {
                            this.handleChangeSelected(properties);
                        }}
                        maxHeight={true}
                    />
                </div>
                <div className="col-span-12 lg:col-span-3">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {this.state.selectedProps.map((node, index) => {
                                        return (
                                            <Draggable key={node} draggableId={node} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        style={provided.draggableProps.style}
                                                        {...provided.draggableProps}
                                                    >
                                                        <div
                                                            className="btn btn-gray"
                                                            style={{
                                                                position: 'relative',
                                                                display: 'block',
                                                                marginBottom: '4px',
                                                            }}
                                                        >
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    left: '5px',
                                                                }}
                                                                title="Ordina proprietà"
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <i className="icon icon-apps"></i>
                                                            </span>
                                                            {this.getNodeLabel(node)}
                                                            <span
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '5px',
                                                                }}
                                                                title="Rimuovi proprietà"
                                                                onClick={() =>
                                                                    this.removeNode(node)
                                                                }
                                                            >
                                                                <i className="icon icon-cross" />
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <hr />
                    <div className="text-right">
                        <button
                            className="btn btn-primary btn-lg btn-block"
                            onClick={this.handleSave}
                        >
                            <Trans id="save" /> <i className="icon icon-check"></i>
                        </button>
                    </div>
                    <div className="mt-2">
                        <Link to="/app">
                            <i className="icon icon-back" /> <Trans id="back:to:article:list" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: userSelectors.getUser(state),
        tableColumns: selectors.getTableColumns(state),
        productProperties: selectors.getProductProperties(state),
    };
}

export default compose(connect(mapStateToProps), withI18n())(CustomizeTablePanel);
