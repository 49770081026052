import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, withI18n, i18nMark } from '@lingui/react';
// import cx from 'classnames';
import useResizeObserver from 'use-resize-observer';
import throttle from 'lodash/throttle';

import Loader from './utils/Loader';
import ArticleTableHeader from './ArticleTableHeader';
import ProductClassFilterWrapper from '../containers/ProductClassFilterWrapper';
// import ArticleTableFilters from '../containers/ArticleTableFilters';
import SortableTableHeader from './utils/SortableTableHeader';
import CollectionsList from '../containers/CollectionsList';
import ArticlePagination from './ArticlePagination';
import CollectionControlsHeader from './CollectionControlsHeader';
import Row from './Row';
import RowBlock from '../containers/RowBlock';
import { VIEW_TYPE_DETAILED, VIEW_TYPE_COMPACT, VIEW_TYPE_BLOCK } from '../constants';
import DynamicTable from './DynamicTable';
import GlobalScrollToTopButton from './utils/GlobalScrollToTopButton';
import DropdownList from './utils/DropdownList';
// import { DEFAULT_VIEWS_TABLE_COLUMNS } from '../sagas/itemsSaga';

const paginationItemsString = i18nMark('pagination:items');

function useThrottledResizeObserver({ active = true, wait, onResizeFn, customRef }) {
    const [size, setSize] = React.useState({});
    const onResize = React.useMemo(() => throttle(onResizeFn || setSize, wait), [onResizeFn, wait]);

    let hookParams = {
        onResize,
    };

    if (customRef) {
        hookParams.ref = customRef;
    }

    if (active === false) {
        hookParams.ref = null;
    }

    const { ref } = useResizeObserver(hookParams);

    return { ref, ...size };
}

function TableSizer() {
    const customRef = React.useRef(null);

    const onResizeFn = ({ width }) => {
        // console.log(width);

        const tableEl = document.getElementsByClassName('--tabulisk-table')[0];

        if (!tableEl) {
            return;
        }

        const tableWidth = parseInt(tableEl.style.minWidth.replace('px', ''));

        const tBody = document.getElementsByClassName('--tabulisk-tbody')[0];
        const tHead = document.getElementsByClassName('--tabulisk-thead')[0];
        const lastHeader = document.querySelectorAll('[role=columnheader]:last-child')[0];

        if (tableWidth < width) {
            tBody.style.width = 'auto';
            tHead.style.width = 'auto';
            lastHeader.style.marginRight = '17px';

            return;
        }

        if (tBody && tHead) {
            tBody.style.width = `${width - 4}px`; // 4 = spessore bordo dx e sx
            tHead.style.width = `${width - 4}px`; // 17 = spessore scrollbar
        }

        if (lastHeader) {
            lastHeader.style.marginRight = '0'; // elimino margine da ultimo header dato che ho impostato la dimensione corretta totale del tHead
        }
    };

    // const onResizeFn = throttle(setSize, 50);

    useThrottledResizeObserver({
        onResizeFn,
        customRef,
        wait: 50,
        active: true,
    });

    return <div ref={customRef}></div>;
}

// function getAvailableProps(item) {
//     if (!item) {
//         return null;
//     }

//     return item.children.map(i => i.property);
// }

export class ArticleTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // viewType: props.defaultTableView || VIEW_TYPE_COMPACT,
            showCollections: false,
            mounted: false,
        };

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                mounted: true,
            });
        }, 100);
    }

    toggleCollections = () => {
        this.setState((prevState) => ({
            showCollections: !prevState.showCollections,
        }));
    };

    // onSetViewType = type => {
    //     this.setState({
    //         viewType: type
    //     });
    // };

    renderTableHeader(availableProps) {
        const {
            sortBy,
            sorting,
            areRowsSelectable,
            isAnyFilterActive,
            onSelectAll,
            onRemoveAll,
            // currentViewType,
        } = this.props;

        // console.log(sorting);

        // console.log(availableProps);

        if (this.getCurrentViewType() === VIEW_TYPE_DETAILED) {
            return (
                <tr>
                    <th />
                    {areRowsSelectable && (
                        <th style={{ width: '65px' }}>
                            <CollectionControlsHeader
                                selectAllDisabled={isAnyFilterActive === false}
                                onSelectAll={onSelectAll}
                                onRemoveAll={onRemoveAll}
                            />
                        </th>
                    )}
                    <SortableTableHeader attr="codice_articolo" onClick={sortBy} sorting={sorting}>
                        {availableProps.includes('codice_articolo') && <Trans id="article:code" />}
                    </SortableTableHeader>
                    <SortableTableHeader
                        attr="descrizione_articolo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        {availableProps.includes('descrizione_articolo') && (
                            <Trans id="article:description" />
                        )}
                    </SortableTableHeader>
                    <SortableTableHeader attr="marca" onClick={sortBy} sorting={sorting}>
                        {availableProps.includes('descrizione_marca') && <Trans id="brand" />}
                    </SortableTableHeader>
                    <th>{availableProps.includes('serie') && <Trans id="series" />}</th>
                    <th>{availableProps.includes('linee') && <Trans id="line" />}</th>
                    <SortableTableHeader attr="modello" onClick={sortBy} sorting={sorting}>
                        {availableProps.includes('modello') && <Trans id="model" />}
                    </SortableTableHeader>
                    <th className="text-center">
                        {availableProps.includes('unita_contenuta') && <Trans id="um" />}{' '}
                        {availableProps.includes('quantita_contenuta') && <Trans id="conf" />}
                    </th>
                    <SortableTableHeader
                        headerClass="text-right"
                        attr="prezzo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        <span style={{ paddingRight: '25px' }}>
                            {availableProps.includes('prezzi') && <Trans id="price" />}
                        </span>
                    </SortableTableHeader>
                </tr>
            );
        }

        if (this.getCurrentViewType() === VIEW_TYPE_BLOCK) {
            return (
                <tr>
                    {areRowsSelectable && (
                        <th style={{ width: '65px' }}>
                            <CollectionControlsHeader
                                selectAllDisabled={isAnyFilterActive === false}
                                onSelectAll={onSelectAll}
                                onRemoveAll={onRemoveAll}
                            />
                        </th>
                    )}
                    <SortableTableHeader attr="codice_articolo" onClick={sortBy} sorting={sorting}>
                        {availableProps.includes('codice_articolo') && <Trans id="article:code" />}
                    </SortableTableHeader>
                    <SortableTableHeader
                        attr="descrizione_articolo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        {availableProps.includes('descrizione_articolo') && (
                            <Trans id="article:description" />
                        )}
                    </SortableTableHeader>
                    <SortableTableHeader attr="marca" onClick={sortBy} sorting={sorting}>
                        {availableProps.includes('descrizione_marca') && <Trans id="brand" />}
                    </SortableTableHeader>
                    <SortableTableHeader attr="modello" onClick={sortBy} sorting={sorting}>
                        {availableProps.includes('modello') && <Trans id="model" />}
                    </SortableTableHeader>
                    <SortableTableHeader
                        headerClass="text-right"
                        attr="prezzo"
                        onClick={sortBy}
                        sorting={sorting}
                    >
                        <span style={{ paddingRight: '25px' }}>
                            {availableProps.includes('prezzi') && <Trans id="price" />}
                        </span>
                    </SortableTableHeader>
                </tr>
            );
        }

        return (
            <tr>
                <SortableTableHeader
                    headerClass="table-cell-brand"
                    attr="marca"
                    onClick={sortBy}
                    sorting={sorting}
                >
                    <Trans id="brand" />
                </SortableTableHeader>
                <SortableTableHeader
                    headerClass="table-cell-code"
                    attr="codice_articolo"
                    onClick={sortBy}
                    sorting={sorting}
                >
                    <Trans id="article:code" />{' '}
                </SortableTableHeader>
                {areRowsSelectable && (
                    <th style={{ width: '65px' }}>
                        <CollectionControlsHeader
                            selectAllDisabled={isAnyFilterActive === false}
                            onSelectAll={onSelectAll}
                            onRemoveAll={onRemoveAll}
                        />
                    </th>
                )}
                <SortableTableHeader attr="descrizione_articolo" onClick={sortBy} sorting={sorting}>
                    <Trans id="article:description" />
                </SortableTableHeader>
                <th
                    className="tooltip"
                    data-tooltip={this.props.i18n._('measure:unit')}
                    style={{ width: '45px' }}
                >
                    <Trans id="um" />
                </th>
                <SortableTableHeader
                    headerClass="text-right table-cell-price"
                    attr="prezzo"
                    onClick={sortBy}
                    sorting={sorting}
                >
                    <Trans id="price" />
                </SortableTableHeader>
            </tr>
        );
    }

    renderRows(availableProps) {
        const {
            items,
            // currentViewType,
            language,
            areRowsSelectable,
            onSelectRow,
            q,
            productProperties,
        } = this.props;

        let i = 0;

        if (items.size === 0) {
            return (
                <tr>
                    <td colSpan="999" className="bg-gray">
                        <div className="empty">
                            <div className="empty-icon">
                                <i className="icon icon-search" />
                            </div>
                            <p className="empty-title h5">
                                <Trans id="article:count" values={{ count: 0 }} />
                            </p>
                            <p className="empty-subtitle">
                                <Trans id="Use filters to re-search" />
                            </p>
                        </div>
                    </td>
                </tr>
            );
        }

        if (this.getCurrentViewType() === VIEW_TYPE_BLOCK) {
            return null;
        }

        return items.map((item) => {
            i++;

            return (
                <Row
                    key={item.id}
                    item={item}
                    index={i}
                    type={this.getCurrentViewType()}
                    q={q}
                    language={language}
                    selectable={areRowsSelectable}
                    onSelectRow={onSelectRow}
                    productProperties={productProperties}
                    availableProps={availableProps}
                />
            );
        });
    }

    renderBlocks(availableProps) {
        const { items, language, areRowsSelectable, onSelectRow, q, productProperties } =
            this.props;

        let i = 0;

        return (
            <div className="grid grid-cols-4 gap-2 px-1 pt-1">
                {items.map((item) => {
                    i++;

                    return (
                        <RowBlock
                            key={item.id}
                            item={item}
                            index={i}
                            language={language}
                            q={q}
                            selectable={areRowsSelectable}
                            onSelect={onSelectRow}
                            productProperties={productProperties}
                            availableProps={availableProps}
                        />
                    );
                })}
            </div>
        );
    }

    getCurrentViewType() {
        if (window.innerWidth < 1024) {
            return VIEW_TYPE_BLOCK;
        }

        return this.props.currentViewType;
    }

    renderClassicTable(availableProps) {
        const { isFetching, i18n, sortBy } = this.props;

        if (window.innerWidth < 1024 && this.getCurrentViewType() === VIEW_TYPE_BLOCK) {
            return (
                <div>
                    <div className="p-1 bg-slate-300">
                        <DropdownList
                            items={[
                                { value: 'codice_articolo', label: i18n._('article:code') },
                                {
                                    value: 'descrizione_articolo',
                                    label: i18n._('article:description'),
                                },
                                { value: 'marca', label: i18n._('brand') },
                                { value: 'modello', label: i18n._('model') },
                                { value: 'prezzo', label: i18n._('price') },
                            ]}
                            className="mt-2"
                            placeholder={i18n._('sort by')}
                            filterWhenValue={false}
                            useVirtualList={false}
                            onChange={(option) => {
                                if (option) {
                                    sortBy(option.value);
                                } else {
                                    sortBy(null);
                                }
                            }}
                        />
                    </div>
                    <div>
                        {isFetching ? (
                            <div>
                                <Loader />
                            </div>
                        ) : (
                            <>
                                {this.renderRows(availableProps)}
                                {this.renderBlocks(availableProps)}
                            </>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <table className="table article-table">
                    <thead>{this.renderTableHeader(availableProps)}</thead>
                    <tbody>
                        {isFetching ? (
                            <tr>
                                <td colSpan="999">
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            this.renderRows(availableProps)
                        )}
                    </tbody>
                </table>
                {this.getCurrentViewType() === VIEW_TYPE_BLOCK &&
                    isFetching === false &&
                    this.renderBlocks(availableProps)}
            </div>
        );
    }

    render() {
        const {
            isFetching,
            total,
            serverTotal,
            onPageChange,
            currentPage,
            pages,
            lastSearchHash,
            sorting,
            sortBy,
            // currentViewType,
            areRowsSelectable,
            onSelectAll,
            onRemoveAll,
            onSelectRow,
            listiniFilter,
            brandFilter,
            // items
            // user
        } = this.props;

        const { showCollections } = this.state;

        // console.log(this.wrapperRef.current ? this.wrapperRef.current.offsetWidth - 10 : null);

        const containerStyle = {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1',
            overFlowY: 'auto',
            overflowX: 'hidden',
            height: 'calc(100vh - 60px)',
        };

        const availableProps = Object.keys(this.props.productProperties);
        // (items && items.count()) > 0
        //     ? getAvailableProps(items.toJS()[0])
        //     : DEFAULT_VIEWS_TABLE_COLUMNS;

        // console.log(availableProps);

        return (
            <div
                className=""
                ref={this.wrapperRef}
                style={this.getCurrentViewType() === VIEW_TYPE_COMPACT ? containerStyle : {}}
            >
                <ArticleTableHeader
                    viewType={this.getCurrentViewType()}
                    setViewType={this.props.onSetViewType}
                    total={serverTotal}
                    displayArticleNumber={lastSearchHash !== null && isFetching === false}
                    onCollectionsBtnClick={this.toggleCollections}
                    isCollectionsShowed={showCollections}
                    listiniFilter={listiniFilter}
                    brandFilter={brandFilter}
                />
                {showCollections && <CollectionsList />}
                {/* <ArticleTableFilters /> */}
                {this.getCurrentViewType() === VIEW_TYPE_COMPACT && <TableSizer />}
                <ProductClassFilterWrapper />
                {this.getCurrentViewType() === VIEW_TYPE_COMPACT && (
                    <DynamicTable
                        width={
                            this.wrapperRef.current
                                ? this.wrapperRef.current.offsetWidth - 10
                                : null
                        }
                        sortBy={sortBy}
                        sorting={sorting}
                        items={this.props.customItems}
                        isFetching={isFetching}
                        selectable={areRowsSelectable}
                        onSelectAll={onSelectAll}
                        onRemoveAll={onRemoveAll}
                        onSelectRow={onSelectRow}
                    />
                )}
                {this.getCurrentViewType() !== VIEW_TYPE_COMPACT &&
                    this.renderClassicTable(availableProps)}
                <ArticlePagination
                    onPageChange={onPageChange}
                    currentPage={currentPage}
                    pages={pages}
                    total={total}
                    itemTranslationKey={paginationItemsString}
                />
                {this.getCurrentViewType() === VIEW_TYPE_COMPACT && (
                    <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
                        <GlobalScrollToTopButton container={this.wrapperRef} />
                    </div>
                )}
                {/* {isFetching === false && ()} */}
            </div>
        );
    }
}

ArticleTable.propTypes = {
    i18n: PropTypes.object,
    areRowsSelectable: PropTypes.bool,
    isFetching: PropTypes.bool,
    items: PropTypes.object,
    customItems: PropTypes.array,
    sortBy: PropTypes.func,
    sorting: PropTypes.object,
    total: PropTypes.number,
    serverTotal: PropTypes.number,
    q: PropTypes.string,
    onPageChange: PropTypes.func,
    currentPage: PropTypes.number,
    pages: PropTypes.number,
    productProperties: PropTypes.object,
    isAnyFilterActive: PropTypes.bool,
    language: PropTypes.string,
};

ArticleTable.defaultProps = {
    items: [],
};

export default withI18n()(ArticleTable);
