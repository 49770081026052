import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withI18n } from '@lingui/react';
import get from 'lodash/get';
import transform from 'lodash/transform';
import omit from 'lodash/omit';

import { showArticolo, getPublicProductInfoMap, fetchPublicEtimClassDetails } from '../api';
import ArticleDetailsBody from './ArticleDetailsBody';
import AppLogo from './utils/AppLogo';
import { normalizeArticleDetails, getDescrizioneMarcaFromArticolo } from '../helpers';
import ScrollToTopButton from './utils/ScrollToTopButton';
import scrollPosHandler from './utils/ScrollPosHandler';
import AppLoader from './utils/AppLoader';
import ErrorPage from './ErrorPage';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import { setProductProperties } from '../actions/appActions';
import { selectors } from '../reducers/appReducer';

function normalizeEtimClassDetails(etimClass) {
    return {
        ...etimClass,
        etim_features: transform(
            etimClass.etim_features,
            (collection, feature) => {
                collection[feature.id] = {
                    ...omit(feature, 'description'),
                    label: feature.description,
                };

                return collection;
            },
            {}
        ),
    };
}

export class ArticlePublic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            details: null,
            article: null,
            // productInfoMap: null,
            etimClassDetails: null,
            error: false,
        };
    }

    async componentDidMount() {
        const { match, location } = this.props;

        let etimClassDetails = {};

        try {
            // FIXME: non posso usare match.params.article perche alcuni articoli hanno / nel nome
            const articleId = location.pathname
                .replace('/public/article/', '')
                .replace(match.params.brand + '/', '');
            const articleRes = showArticolo(match.params.brand, articleId);

            const productInfoMapRes = getPublicProductInfoMap();

            const articleData = await articleRes;
            const productInfoMapData = await productInfoMapRes;

            const details = normalizeArticleDetails(articleData.data);

            if (details.etim.id !== null) {
                const etimClassRes = await fetchPublicEtimClassDetails(details.etim.id);

                etimClassDetails = normalizeEtimClassDetails(etimClassRes.data);
            }

            let article = {};

            if (articleData.data && articleData.data.metadata.immagine_prodotto) {
                article.immagine_prodotto = articleData.data.metadata.immagine_prodotto;
            }

            this.props.dispatch(setProductProperties(productInfoMapData.data));

            this.setState({
                isLoading: false,
                article,
                details,
                // productInfoMap: productInfoMapData.data,
                etimClassDetails,
            });
        } catch (e) {
            this.setState({
                isLoading: false,
                error: e,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoading === true && this.state.isLoading === false) {
            this.props.onMount();
        }
    }

    getMarcaArticolo() {
        return getDescrizioneMarcaFromArticolo(this.state.details, this.getLocale());
    }

    getCodiceArticolo() {
        return get(this.state.details, 'identificazione.codice_articolo.value');
    }

    getDescrizioneArticolo() {
        return get(
            this.state.details,
            `identificazione.descrizione_articolo.value.${this.getLocale()}`
        );
    }

    getLocale() {
        return getLocaleFromLanguageCode(this.props.language);
    }

    render() {
        const { isLoading, article, details, etimClassDetails, error } = this.state;

        if (isLoading) {
            return <AppLoader />;
        }

        if (error) {
            return (
                <ErrorPage
                    message={this.props.i18n._('article:not:found')}
                    showBackButton={false}
                />
            );
        }

        let btnStyle = {
            position: 'fixed',
            bottom: '10px',
        };

        if (window.innerWidth < 640) {
            btnStyle.left = '15px;';
        } else {
            btnStyle.right = '15px';
        }

        return (
            <div className="bg-primary login-wrapper">
                <div className="container grid-xl">
                    <div className="form-spaced">
                        <div className="text-center" style={{ marginBottom: '16px' }}>
                            <AppLogo />
                        </div>
                        <div className="h5">
                            {this.getMarcaArticolo()}
                            {' - '}
                            {this.getCodiceArticolo()}
                            {' - '}
                            <span style={{ fontWeight: 200 }}>{this.getDescrizioneArticolo()}</span>
                        </div>
                        <ArticleDetailsBody
                            article={article}
                            details={details}
                            isPreview={false}
                            initialCategories={[]}
                            language={this.props.language}
                            // productInfoMap={productInfoMap}
                            etimClassDetails={etimClassDetails}
                        />
                        {this.props.scrollPos > 300 && (
                            <div style={btnStyle}>
                                <ScrollToTopButton inverse={true} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        language: selectors.getLanguage(state),
    };
}

const ConnectedArticlePublic = connect(mapStateToProps)(ArticlePublic);

const ArticlePublicWithi18n = withI18n()(ConnectedArticlePublic);

export default scrollPosHandler(ArticlePublicWithi18n);
