import { all, call, put, select, takeEvery, fork } from 'redux-saga/effects';
import omit from 'lodash/omit';
// import includes from 'lodash/includes';

import { actions, actionTypes } from '../actions/filtersActions';
import { actions as productClassesActions } from '../actions/productClassesActions';
import { fetchItems } from '../actions/itemsActions';
// import { setTree } from '../actions/sectorsActions';
import { selectors } from '../reducers/filtersReducer';
import { fetchTree } from './sectorsSaga';
import * as api from '../api';
import * as constants from '../constants';

// const resetProductClassesKeys = [
//     constants.FULL_TEXT_FILTER,
//     constants.CATALOG_FILTER,
//     constants.LINE_FILTER,
//     constants.MODEL_FILTER,
//     constants.SERIES_FILTER
// ];

export const brandDependentFilters = [
    // constants.CATALOG_FILTER,
    constants.LINE_FILTER,
    constants.MODEL_FILTER,
    constants.SERIES_FILTER,
    constants.LISTINI_FILTER,
];

function* setFilterValueSaga(action) {
    // const { key } = action.payload;

    // if (includes(resetProductClassesKeys, key)) {
    //     yield put(productClassesActions.setProductClassValue(null));
    // }

    yield put(fetchItems());
}

function* changeBrandFilterSaga(action) {
    const actualBrandFilter = yield select(selectors.getFilter, constants.BRAND_FILTER);

    if (
        action.payload === null ||
        (actualBrandFilter.value && actualBrandFilter.value.value !== action.payload.value)
    ) {
        yield put(actions.resetFilters(brandDependentFilters));
    }

    yield put(actions.setFilterValue(constants.BRAND_FILTER, action.payload));

    const updateFilterAction =
        action.payload === null ? actions.disableFilters : actions.enableFilters;

    yield put(updateFilterAction(brandDependentFilters));
}

export function* refreshFiltersValues(apiPayload = null) {
    if (apiPayload === null) {
        apiPayload = yield select(selectors.getApiPayload);
    }

    // console.log(apiPayload);

    yield fork(fetchTree);

    const brandApiPayload = omit(apiPayload, ['sigle_marca']);

    const [brandsRes, statusRes, priceRangeRes, etimClassRes, listiniRes] = yield all([
        call(api.fetchBrandFilter, brandApiPayload),
        call(api.fetchStatusFilter, apiPayload),
        call(api.fetchPriceRangeFilter, apiPayload),
        call(api.fetchEtimClassFilter, apiPayload),
        call(api.fetchListini, apiPayload),
        // call(api.fetchClassificationTree, apiPayload)
    ]);

    // console.log(priceRangeRes);

    yield put(actions.setFilterOptions(constants.BRAND_FILTER, brandsRes.data));
    yield put(actions.setFilterOptions(constants.STATUS_FILTER, statusRes.data));
    yield put(actions.setPriceRange(priceRangeRes.data));
    yield put(productClassesActions.setProductClassOptions(etimClassRes.data));
    yield put(actions.setFilterOptions(constants.LISTINI_FILTER, listiniRes.data));
    // yield put(setTree(treeRes.data));

    if (apiPayload.sigle_marca !== undefined && apiPayload.sigle_marca.count() === 1) {
        yield fork(fetchFilterData, api.fetchLineeFilter, apiPayload, constants.LINE_FILTER);
        yield fork(fetchFilterData, api.fetchModelliFilter, apiPayload, constants.MODEL_FILTER);
        yield fork(fetchFilterData, api.fetchSeriesFilter, apiPayload, constants.SERIES_FILTER);

        // try {
        //     const [lineRes, modelRes, seriesRes] = yield all([
        //         call(api.fetchLineeFilter, apiPayload),
        //         call(api.fetchModelliFilter, apiPayload),
        //         call(api.fetchSeriesFilter, apiPayload)
        //     ]);

        //     yield put(actions.setFilterOptions(constants.LINE_FILTER, lineRes.data));
        //     yield put(actions.setFilterOptions(constants.MODEL_FILTER, modelRes.data));
        //     yield put(actions.setFilterOptions(constants.SERIES_FILTER, seriesRes.data));
        // } catch(err) {
        //     console.error(err);
        // }
    }
}

function* fetchFilterData(apiFn, apiPayload, filterName) {
    try {
        const res = yield call(apiFn, apiPayload);

        yield put(actions.setFilterOptions(filterName, res.data));
    } catch (err) {
        console.error(err);
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
    takeEvery(actionTypes.SET_FILTER_VALUE, setFilterValueSaga),
    takeEvery(actionTypes.CHANGE_BRAND_FILTER_VALUE, changeBrandFilterSaga),
];
