import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfiniteTree from 'react-infinite-tree';
import { Record, List, Seq } from 'immutable';
import TreeUtils from 'immutable-treeutils';
import { withI18n } from '@lingui/react';
import cx from 'classnames';
import { filter, includes, intersection, uniq, sortBy, get } from 'lodash';

import { getAllProductProperties } from '../../api';
import { getLocaleFromLanguageCode } from '../../intl-helpers';

const treeUtils = new TreeUtils(Seq(), 'id', 'children');

const NodeRecord = Record({
    id: null,
    name: null,
    code: null,
    children: List([]),
});

function createTreeFromData(data, language) {
    let root = List([]);

    const langCode = getLocaleFromLanguageCode(language);
    const codeRootToFilter = ['old_assets', 'ordinamenti', 'assets'];
    // const codeLeafToFilter = ['etim_features'];
    const codeLeafToFilter = [];

    // console.log(data);

    // Popolo gli elementi di root
    data.forEach((prop) => {
        // console.log(prop);
        if (prop.parent_id === null && !includes(codeRootToFilter, prop.code)) {
            root = root.push(
                NodeRecord({
                    id: prop.id,
                    name: get(prop.label, langCode, prop.code),
                    code: prop.code,
                })
            );
        }
    });

    let tree = NodeRecord({
        id: 'root',
        children: root,
    });

    // Popolo tutto il resto
    sortBy(data, ['position']).forEach((prop) => {
        if (prop.parent_id !== null && !includes(codeLeafToFilter, prop.code)) {
            const path = treeUtils.byId(tree, prop.parent_id);

            if (path) {
                let node = tree.getIn(path);

                node = node.set(
                    'children',
                    node.children.push(
                        NodeRecord({
                            id: prop.id,
                            name: prop.label.it_IT,
                            code: prop.code,
                        })
                    )
                );

                tree = tree.setIn(path, node);
            }
        }
    });

    return tree.get('children');
}

export class PropertiesTreeWidget extends Component {
    tree = null;

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            treeData: null,
            filterValue: '',
        };
    }

    async componentDidMount() {
        const { data } = await getAllProductProperties();

        const treeData = createTreeFromData(data, this.props.i18n.language);

        this.setState({
            isFetching: false,
            treeData,
        });

        if (this.tree) {
            this.tree.loadData(treeData.toJS());
        }
    }

    handleItemClick = (node) => {
        const { selectedNodes, selectOnlyLeaf } = this.props;

        let op = 'select';

        if (includes(selectedNodes, node.code)) {
            op = 'deselect';
        }

        const nodes = [node.code];

        if (node.hasChildren()) {
            node.getChildren().forEach((childNode) => {
                nodes.push(childNode.code);
                if (childNode.hasChildren()) {
                    childNode.getChildren().forEach((grandChildNode) => {
                        nodes.push(grandChildNode.code);
                    });
                }
            });
        }

        if (selectOnlyLeaf === false) {
            if (op === 'select') {
                const parent = node.getParent();

                // Se l'azione è di selezionare aggiungo il padre alla lista dei nodi selezionati
                if (parent) {
                    nodes.push(parent.code);

                    // FIXME: sarebbe da fare la stessa cosa anche quando deseleziono un nodo, dovrei verificare che anche il suo "nonno"
                    // abbia dei figli selezionati
                    const grandParent = parent.getParent();

                    if (grandParent) {
                        nodes.push(grandParent.code);
                    }
                }
            } else {
                const parent = node.getParent();

                // Altrimenti verifico se il padre ha altri nodi selezionati, in caso contrario lo deseleziono
                if (parent) {
                    const childCodes = parent.getChildren().map((childNode) => {
                        return childNode.code;
                    });

                    const matches = intersection(selectedNodes, childCodes);

                    // matches sarà sempre almeno 1 perchè nei selectedNodes è incluso per lo meno il nodo che sto deselezionando adesso,
                    // quindi per caprie se ci sono altri nodi selezionati devo trovarne 2 o più
                    if (matches.length < 2) {
                        nodes.push(parent.code);
                    }
                }
            }
        }

        //console.log(selectedNodes);
        //console.log(nodes);

        const newSelectedNodes =
            op === 'select'
                ? [].concat(selectedNodes, nodes)
                : filter(selectedNodes, (n) => !includes(nodes, n));

        //console.log(uniq(newSelectedNodes));

        /*this.setState({
            selectedNodes: uniq(newSelectedNodes)
        });*/

        this.props.onChange({
            selectedNodes: uniq(newSelectedNodes),
            op,
            nodes,
        });

        // this.tree.selectNode(node);
    };

    filterTree = () => {
        const filterOptions = {
            caseSensitive: false,
            exactMatch: false,
            includeAncestors: true,
            includeDescendants: true,
        };

        console.log(this.tree);

        this.tree.filter(this.state.filterValue, filterOptions);
    };

    // removeFilter = () => {
    //     this.tree.unfilter();
    // }

    render() {
        const { selectedNodes, selectOnlyLeaf, i18n, maxHeight } = this.props;

        return (
            <>
                <div className="input-group" style={{ marginBottom: '10px' }}>
                    <input
                        type="text"
                        className="form-input"
                        placeholder={i18n._('filter:by')}
                        onChange={(e) => this.setState({ filterValue: e.target.value })}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setTimeout(() => {
                                    this.filterTree();
                                }, 0);
                            }
                        }}
                        value={this.state.filterValue}
                    />
                    <button
                        type="button"
                        className="btn btn-secondary input-group-btn"
                        onClick={this.filterTree}
                    >
                        <i className="icon icon-search" />
                    </button>
                </div>
                {/* <button onClick={this.removeFilter} className="btn">Remove Filter</button> */}
                <InfiniteTree
                    ref={(node) => (this.tree = node ? node.tree : null)}
                    autoOpen={false}
                    selectable={true}
                    height={maxHeight ? window.innerHeight - 200 : 500}
                    width={window.innerWidth < 800 ? '200%' : '100%'}
                    rowHeight={26}
                    rowRenderer={({ tree, node }) => {
                        const { id, name, code, state } = node;
                        const { depth, open } = state;
                        const { options } = tree;
                        const more = node.hasChildren();

                        const allChildren = tree.flattenChildNodes(node).map((n) => n.code);

                        const isSelected = includes(selectedNodes, code);

                        return (
                            <div
                                className={cx('infinite-tree-item', {
                                    // 'bg-secondary': isSelected
                                })}
                                data-id={id}
                            >
                                <div
                                    className="infinite-tree-node"
                                    style={{ marginLeft: depth * 18 }}
                                >
                                    {more && open && (
                                        <span
                                            className={cx(options.togglerClass, 'c-hand')}
                                            onClick={() => tree.closeNode(node)}
                                        >
                                            <i className="icon icon-small icon-arrow-down mr-1 text-primary" />
                                        </span>
                                    )}
                                    {more && !open && (
                                        <span
                                            className={cx(
                                                options.togglerClass,
                                                'c-hand',
                                                'infinite-tree-closed'
                                            )}
                                            onClick={() => tree.openNode(node)}
                                        >
                                            <i className="icon icon-small icon-plus mr-1 text-primary" />
                                        </span>
                                    )}
                                    {!more && (
                                        <span
                                            className={cx(
                                                options.togglerClass,
                                                'infinite-tree-closed'
                                            )}
                                        >
                                            <i className="icon icon-small icon-minus mr-1 text-primary" />
                                        </span>
                                    )}
                                    <span className={cx('infinite-tree-title')}>
                                        {(selectOnlyLeaf === false || more === false) && (
                                            <span className="">
                                                <label
                                                    className="form-checkbox form-checkbox-inline"
                                                    style={{ paddingRight: '0.1rem' }}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={isSelected}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={() => this.handleItemClick(node)}
                                                    />
                                                    <i className="form-icon" />
                                                </label>
                                            </span>
                                            // <input
                                            //     type="checkbox"
                                            //     style={{
                                            //         position: 'relative',
                                            //         top: '-5px',
                                            //         marginRight: '2px'
                                            //     }}
                                            //     checked={includes(selectedNodes, code)}
                                            //     onChange={() => this.handleItemClick(node)}
                                            // />
                                        )}
                                        <span
                                            className={cx('infinite-tree-leaf', {
                                                'text-bold': isSelected,
                                                'text-underline':
                                                    intersection(allChildren, selectedNodes)
                                                        .length > 0,
                                                'text-italic':
                                                    intersection(allChildren, selectedNodes)
                                                        .length > 0,
                                            })}
                                        >
                                            {name}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        );
                    }}
                />
            </>
        );
    }
}

PropertiesTreeWidget.propTypes = {
    selectedNodes: PropTypes.array,
    onChange: PropTypes.func,
    selectOnlyLeaf: PropTypes.bool,
    maxHeight: PropTypes.bool,
};

PropertiesTreeWidget.defaultProps = {
    selectOnlyLeaf: false,
    maxHeight: false,
};

export default withI18n()(PropertiesTreeWidget);
