import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import toPairs from 'lodash/toPairs';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
// import _ from 'lodash';

import Loader from './utils/Loader';
import ErrorBoundary from './utils/ErrorBoundary';
// import { actions as productClassesActions } from '../actions/productClassesActions';
import ProductClassAttributeFilters from './ProductClassAttributeFilters';
// import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import ProductClassFilterActive from './ProductClassFilterActive';
import { setFilterValue } from '../actions/filtersActions';
import { setProductClassValue } from '../actions/productClassesActions';
import { isBoolean, isObject } from 'lodash';
import { toggleTreeItem } from '../actions/sectorsActions';
import { getLocaleFromLanguageCode } from '../intl-helpers';
// import noPhotoImg from '../images/no-photo.png';

class ActiveFilterLabel extends Component {
    render() {
        const { label, onClear } = this.props;

        return (
            <span className="label label-secondary" style={{ marginRight: '5px' }}>
                {label}{' '}
                <i
                    onClick={onClear}
                    className="icon icon-cross c-hand"
                    style={{
                        position: 'relative',
                        top: '-2px',
                    }}
                />
            </span>
        );
    }
}

export class ProductClassFilter extends Component {
    state = {
        showFilters: true,
        lastSearchHash: null,
    };

    toggleFilters = () => {
        this.setState((prevState) => ({
            showFilters: !prevState.showFilters,
        }));
    };

    // toggleImage = () => {
    //     this.setState(prevState => ({
    //         showImage: !prevState.showImage
    //     }));
    // };

    handleChangeBooleanField = (key, value) => {
        this.props.onSetProductClassAttributeLogic(key, value);
    };

    handleChangeNumberField = (key, values) => {
        this.props.onSetProductClassAttributeNumeric(key, values);
    };

    // handleFilterClick = () => {
    //     this.props.fetchItems();

    //     this.setState({
    //         lastSearchHash: this.props.activeFilters.hashCode()
    //     });
    // };

    handleResetClick = () => {
        this.props.resetProductClassAttributeAll();

        // this.setState({
        //     lastSearchHash: null
        // });
    };

    // isDisabledFilterBtn() {
    //     if (
    //         this.state.lastSearchHash === null &&
    //         this.props.activeFilters.count() === 0
    //     ) {
    //         return true;
    //     }

    //     return (
    //         this.state.lastSearchHash === this.props.activeFilters.hashCode()
    //     );
    // }

    isDisabledResetBtn() {
        // if (this.state.lastSearchHash === null) {
        //     return true;
        // }

        return this.props.activeFilters.count() === 0;
    }

    handleRemoveFilter(key, value) {
        if (key === 'sector') {
            this.props.dispatch(toggleTreeItem(value));
        }

        // if (key === 'brand') {
        //     this.props.resetBrandFilter();

        //     return;
        // }

        if (key === 'priceRange') {
            this.props.dispatch(setFilterValue(key, [0, 0]));

            return;
        }

        if (key === 'fullText') {
            this.props.dispatch(setFilterValue(key, ''));

            return;
        }

        if (key === 'productClass') {
            this.props.dispatch(setProductClassValue(null));

            return;
        }

        if (key === 'replacements' || key === 'optionals') {
            this.props.dispatch(setFilterValue(key, false));

            return;
        }

        this.props.dispatch(setFilterValue(key, { value }));
    }

    render() {
        const {
            i18n,
            isFetching,
            productClassFilter,
            activeFilters,
            attributes,
            onCheckboxToggle,
            onAttributeResetClick,
            fetchProductClassAttributeData,
            globalActiveFilters,
            language,
            // validFilterOptions,
        } = this.props;

        // console.log(globalActiveFilters);

        const { showFilters /*, showImage*/ } = this.state;

        const hasAttributes = attributes.size > 0;

        const active = productClassFilter.value !== null;
        const showLoader = active && isFetching;

        const langCode = getLocaleFromLanguageCode(language);

        return (
            <>
                <div className="bg-gray p-2 grid grid-cols-12 pt-4 gap-1">
                    {/* <div className="column col-2 text-right">
                    <div className="text-uppercase text-primary mt-1">
                        <Trans id="Product class" />
                    </div>
                </div> */}
                    <div className="col-span-12 lg:col-span-8 order-3 lg:order-1">
                        <div className="">
                            {/* <div className="col-12">
                            <DropdownListFilterWrapper
                                filter={productClassFilter}
                                validFilterOptions={validFilterOptions}
                                placeholder={i18n._('filter:product:class')}
                                itemToString={option => {
                                    return option
                                        ? `${
                                              option.code ? option.code : '-'
                                          } ${getOptionLabelLocalized(language)(option)}`
                                        : '';
                                }}
                                itemToSortString={getOptionLabelLocalized(language)}
                                onChange={item =>
                                    this.props.dispatch(
                                        productClassesActions.setProductClassValue(item)
                                    )
                                }
                            />
                        </div> */}
                            <div className="">
                                <div>
                                    {toPairs(globalActiveFilters).map(([key, acFilter]) => {
                                        // console.log(key, acFilter);

                                        if (!acFilter) {
                                            return null;
                                        }

                                        if (key === 'sector') {
                                            return (
                                                <div className="float-left" key={key}>
                                                    <span className="text-bold">
                                                        {acFilter.taxonomy.label[langCode]}:
                                                    </span>{' '}
                                                    <ActiveFilterLabel
                                                        label={acFilter.value.name[langCode]}
                                                        onClear={() =>
                                                            this.handleRemoveFilter(
                                                                key,
                                                                acFilter.value.id
                                                            )
                                                        }
                                                    />
                                                </div>
                                            );
                                        }

                                        if (!isArray(acFilter) && isObject(acFilter)) {
                                            return (
                                                <div className="float-left" key={key}>
                                                    <span className="text-bold">
                                                        {i18n._(key)}:
                                                    </span>{' '}
                                                    <ActiveFilterLabel
                                                        key={key}
                                                        label={acFilter.label[langCode]}
                                                        onClear={() =>
                                                            this.handleRemoveFilter(key, null)
                                                        }
                                                    />
                                                </div>
                                            );
                                        }

                                        if (isString(acFilter) || isBoolean(acFilter)) {
                                            return (
                                                <div className="float-left" key={key}>
                                                    <span className="text-bold">
                                                        {i18n._(key)}:
                                                    </span>{' '}
                                                    <ActiveFilterLabel
                                                        key={key}
                                                        label={
                                                            isBoolean(acFilter) ? 'Si' : acFilter
                                                        }
                                                        onClear={() =>
                                                            this.handleRemoveFilter(key, null)
                                                        }
                                                    />
                                                </div>
                                            );
                                        }

                                        if (acFilter.length === 0) {
                                            return null;
                                        }

                                        if (key === 'priceRange') {
                                            return (
                                                <div className="float-left" key={key}>
                                                    <span className="text-bold">
                                                        {i18n._(key)}:
                                                    </span>{' '}
                                                    <ActiveFilterLabel
                                                        label={acFilter.join(' - ')}
                                                        onClear={() =>
                                                            this.handleRemoveFilter(key, null)
                                                        }
                                                    />
                                                </div>
                                            );
                                        }

                                        return (
                                            <div className="float-left" key={key}>
                                                <span className="text-bold">{i18n._(key)}:</span>{' '}
                                                <>
                                                    {acFilter.map((option) => {
                                                        if (!option) {
                                                            return null;
                                                        }

                                                        return (
                                                            <ActiveFilterLabel
                                                                key={option.value}
                                                                label={option.label[langCode]}
                                                                onClear={() =>
                                                                    this.handleRemoveFilter(
                                                                        key,
                                                                        option.value
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </>
                                            </div>
                                        );
                                    })}
                                </div>
                                {!showLoader && active && (
                                    <ProductClassFilterActive
                                        activeFilters={activeFilters}
                                        language={language}
                                        attributes={attributes}
                                        showHeader={active}
                                        onAttributeResetClick={onAttributeResetClick}
                                        onCheckboxToggle={onCheckboxToggle}
                                        onChangeBooleanField={this.handleChangeBooleanField}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-4 text-right order-1 lg:order-2">
                        <div className="form-group">
                            {hasAttributes && (
                                <label className="form-switch" style={{ display: 'inline-block' }}>
                                    <input
                                        disabled={!active}
                                        type="checkbox"
                                        checked={showFilters}
                                        onChange={this.toggleFilters}
                                    />
                                    <i className="form-icon" /> <Trans id="Show filters ETIM" />
                                </label>
                            )}
                            {/* <label className="form-switch">
                            <input
                                disabled={!active}
                                type="checkbox"
                                checked={showImage}
                                onChange={this.toggleImage}
                            />
                            <i className="form-icon" /> Mostra
                            immagine
                        </label> */}
                        </div>
                        {/* {this.state.showImage &&
                        !showLoader &&
                        active && (
                            <img
                                src={noPhotoImg}
                                className="img-responsive float-right"
                                alt="Classe prodotto"
                            />
                        )}*/}
                    </div>
                    {showLoader && (
                        <div className="text-center col-span-12">
                            <Loader height={100} />
                        </div>
                    )}
                    <ErrorBoundary>
                        {this.state.showFilters && (
                            <>
                                {!showLoader && active && (
                                    <Fragment>
                                        <div className="col-span-6 order-2 lg:order-3 self-center lg:col-span-12 text-right lg:!text-center lg:mb-2">
                                            {hasAttributes && (
                                                <Fragment>
                                                    {/* <button
                                                        className="btn btn-sm btn-primary btn-rounded"
                                                        disabled={this.isDisabledFilterBtn()}
                                                        onClick={this.handleFilterClick}
                                                    >
                                                        <Trans id="apply:filter" />
                                                    </button>{' '} */}
                                                    <button
                                                        className="btn btn-sm btn-primary btn-rounded"
                                                        disabled={this.isDisabledResetBtn()}
                                                        onClick={this.handleResetClick}
                                                    >
                                                        <Trans id="Reset filters ETIM" />
                                                    </button>
                                                </Fragment>
                                            )}
                                            {!hasAttributes && (
                                                <div className="empty empty-short">
                                                    <p className="empty-title h5">
                                                        <Trans id="No feature present" />
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </>
                        )}
                    </ErrorBoundary>
                </div>
                {this.state.showFilters && (
                    <div className="bg-gray p-2 grid grid-cols-12 pt-4 gap-1">
                        <ProductClassAttributeFilters
                            etimClassId={
                                productClassFilter.value ? productClassFilter.value.value : null
                            }
                            activeFilters={activeFilters}
                            attributes={attributes}
                            language={language}
                            onCheckboxToggle={onCheckboxToggle}
                            onAttributeResetClick={onAttributeResetClick}
                            onChangeBooleanField={this.handleChangeBooleanField}
                            onChangeNumberField={this.handleChangeNumberField}
                            onFetchProductClassAttributeData={fetchProductClassAttributeData}
                            mainLoaderShowing={showLoader}
                        />
                    </div>
                )}
            </>
        );
    }
}

ProductClassFilter.propTypes = {
    attributes: PropTypes.object,
    activeFilters: PropTypes.object,
    i18n: PropTypes.object,
    language: PropTypes.string,
    productClassFilter: PropTypes.object,
    onAttributeResetClick: PropTypes.func.isRequired,
    onCheckboxToggle: PropTypes.func.isRequired,
    resetProductClassAttributeAll: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchProductClassAttributeData: PropTypes.func.isRequired,
    validFilterOptions: PropTypes.object,
};

export default withI18n()(ProductClassFilter);

/*<span key={option.value} style={{ marginRight: '5px' }}>
    <span>{option.label.it_IT}</span>{' '}
    <i
        onClick={() =>
            this.handleRemoveFilter(key, option.value)
        }
        className="form-icon icon icon-cross c-hand"
        style={{
            position: 'relative',
            top: '-2px'
        }}
    ></i>
</span>*/
