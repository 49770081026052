import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import Highlighter from 'react-highlight-words';
import { Trans, withI18n } from '@lingui/react';
import { Portal } from 'react-portal';
import format from 'date-fns/format';
// import get from 'lodash/get';
import cx from 'classnames';
import find from 'lodash/find';

import LimitText from './utils/LimitText';
import { getAssetThumbUrl, getEtimImageUrl, getAssetURL } from '../api';
import noPhotoImg from '../images/no-photo.png';
import ArticleDetailsModal from './ArticleDetailsModal';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import { getDetailValue } from '../utils/dataFormaters';

function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

function getProperty(code, productProperties) {
    return productProperties[code];
}

function getPropertyValue(code, itemProps) {
    return find(itemProps, (prop) => prop.property === code);
}

class RowBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
        };
    }

    getPropertyDetailValue(code) {
        const { item, language, productProperties, i18n } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        const result = getDetailValue(
            getPropertyValue(code, item.children),
            getProperty(code, productProperties),
            locale,
            productProperties,
            i18n
        );

        if (!result) {
            return '-';
        }

        return result;
    }

    getValiditaPrezzo() {
        const { item } = this.props;

        if (item.prezzo_attuale && item.prezzo_attuale.data_inizio_validita_prezzo) {
            return format(item.prezzo_attuale.data_inizio_validita_prezzo.date, 'DD/MM/YYYY');
        }

        return null;
    }

    openModal = () => {
        this.setState({
            showModal: true,
        });
    };

    closeModal = () => {
        this.setState({
            showModal: false,
        });
    };

    getItemImage(item) {
        if (
            item.metadata &&
            item.metadata.immagine_prodotto &&
            item.metadata.immagine_prodotto.url
        ) {
            return getAssetThumbUrl(item.metadata.immagine_prodotto.url);
        }

        const etimClass = find(item.children, { property: 'etim_class' });

        if (etimClass && etimClass.value && etimClass.value.name) {
            return getEtimImageUrl(etimClass.value.name);
        }

        return noPhotoImg;
    }

    render() {
        const {
            index,
            item,
            // productInfoMap,
            // language,
            onSelect,
            q,
            selectable,
            availableProps,
        } = this.props;

        // console.log(item.toJS());

        //const locale = getLocaleFromLanguageCode(language);

        const prezzo = this.getPropertyDetailValue('prezzo_listino');
        const validita_prezzo = this.getPropertyDetailValue('data_inizio_validita_prezzo');

        // console.log(item.toJS());

        return (
            <Fragment>
                {this.state.showModal && (
                    <Portal>
                        <ArticleDetailsModal
                            article={item}
                            onClose={this.closeModal}
                            containerId={`article-detail-modal-${item.id}`}
                        />
                    </Portal>
                )}
                <div
                    className={cx(
                        'card c-zoom-in rounded-sm col-span-2 lg:col-span-1',
                        isOdd(index) ? 'bg-alternate-row' : 'bg-white'
                    )}
                    onClick={this.openModal}
                >
                    <div className="card-image-wrapper lg:flex p-2">
                        <div className="card-image lg:flex-grow">
                            <div
                                className="table-img-preview m-auto lg:m-0"
                                style={{ height: '130px' }}
                            >
                                <Img
                                    // src={this.getItemImage(item)}
                                    src={
                                        item.metadata && item.metadata.immagine_prodotto
                                            ? getAssetURL(item.metadata.immagine_prodotto.url)
                                            : noPhotoImg
                                    }
                                    className="img-responsive m-a"
                                    loader={
                                        <div className="rounded bg-secondary table-img-preview" />
                                    }
                                    style={{
                                        maxHeight: '125px',
                                    }}
                                />
                            </div>
                        </div>
                        <div className="card-image-side lg:flex-grow-0 lg:w-20 pl-1 min-h-[4.5rem] hidden lg:block">
                            {selectable && (
                                <div
                                    className="float-right"
                                    style={{
                                        position: 'relative',
                                        top: '-8px',
                                        left: '10px',
                                    }}
                                >
                                    <span className="form-group">
                                        <label
                                            className="form-checkbox form-checkbox-inline"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onSelect(item.id);
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={item.in_collection}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={() => {}}
                                            />
                                            <i className="form-icon" />
                                        </label>
                                    </span>
                                </div>
                            )}
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('unita_contenuta') && <Trans id="um" />}
                            </div>
                            <div
                                className="tooltip"
                                data-tooltip={this.getPropertyDetailValue('unita_ordine')}
                            >
                                {this.getPropertyDetailValue('unita_contenuta')}
                            </div>
                            <hr className="hr-light" />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('quantita_contenuta') && (
                                    <Trans id="conf" />
                                )}
                            </div>
                            {this.getPropertyDetailValue('quantita_contenuta')}
                        </div>
                    </div>
                    <div className="card-header">
                        <div className="card-title h6">
                            <Highlighter
                                searchWords={[q]}
                                textToHighlight={this.getPropertyDetailValue('codice_articolo')}
                            />
                        </div>
                        <div className="card-subtitle">
                            <LimitText
                                limit={25}
                                highlight={q}
                                text={this.getPropertyDetailValue('descrizione_articolo')}
                            />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="card-body-wrapper">
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('descrizione_marca') && (
                                    <Trans id="brand" />
                                )}
                            </div>
                            <LimitText
                                limit={16}
                                text={this.getPropertyDetailValue('descrizione_marca')}
                            />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('serie') && <Trans id="series" />}
                            </div>
                            <LimitText limit={16} text={this.getPropertyDetailValue('serie')} />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('linee') && <Trans id="line" />}
                            </div>
                            <LimitText limit={16} text={this.getPropertyDetailValue('linea')} />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('modello') && <Trans id="model" />}
                            </div>
                            <LimitText limit={16} text={this.getPropertyDetailValue('modello')} />
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="lg:hidden">
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('unita_contenuta') && <Trans id="um" />}
                            </div>
                            <div
                                className="tooltip"
                                data-tooltip={this.getPropertyDetailValue('unita_ordine')}
                            >
                                {this.getPropertyDetailValue('unita_contenuta')}
                            </div>
                            <hr className="hr-light" />
                            <div className="text-label text-uppercase text-small">
                                {availableProps.includes('quantita_contenuta') && (
                                    <Trans id="conf" />
                                )}
                            </div>
                            {this.getPropertyDetailValue('quantita_contenuta')}
                        </div>
                        {item.prezzo !== null ? (
                            <Fragment>
                                <div className="table-price-lg">{prezzo && `€ ${prezzo}`}</div>
                                <div className="table-date-sm text-error">
                                    {validita_prezzo && (
                                        <Fragment>
                                            <Trans id="from" /> {validita_prezzo}
                                        </Fragment>
                                    )}
                                </div>
                            </Fragment>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

RowBlock.propTypes = {
    bg: PropTypes.string,
    index: PropTypes.number,
    onCloseButtonClick: PropTypes.func,
    onSelect: PropTypes.func,
    showDetails: PropTypes.bool,
};

export default withI18n()(RowBlock);
