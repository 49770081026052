import React, { Fragment, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { useQuery } from 'react-query';
// import { useTable, useFlexLayout } from 'react-table';
import { withI18n, Trans } from '@lingui/react';
import { DataTable } from '@ingruz/tabulisk';
import { Portal } from 'react-portal';
import cx from 'classnames';
import includes from 'lodash/includes';
import get from 'lodash/get';

import { getDetailValue } from '../utils/dataFormaters';
import { selectors } from '../reducers/appReducer';
import { selectors as filterSelectors } from '../reducers/filtersReducer';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import CollectionControlsHeader from './CollectionControlsHeader';
import Loader from './utils/Loader';
import ArticleDetailsModal from './ArticleDetailsModal';
// import ArticleDetails from '../containers/ArticleDetails';

function isOdd(n) {
    return Math.abs(n % 2) === 1;
}

const staticColumnWidthMap = {
    codice_articolo: 200,
    linea: 200,
    modello: 200,
    serie: 200,
    stato: 200,
};

function getColumnWidth(propertyCode, properties, locale) {
    if (staticColumnWidthMap[propertyCode]) {
        return staticColumnWidthMap[propertyCode];
    }

    if (properties) {
        return properties.label[locale].length * 12 + 10;
    }

    return 200;
}

function buildTableColumns(
    choosenProperties,
    productProperties,
    locale,
    i18n,
    selectable = false,
    onSelectAll,
    onRemoveAll,
    isAnyFilterActive,
    onSelectRow
) {
    // console.log(choosenProperties);

    const baseColumns = choosenProperties.map((propertyCode) => {
        return {
            Header: productProperties[propertyCode]
                ? productProperties[propertyCode].label[locale]
                : 'N.d.',
            accessor: propertyCode,
            width: getColumnWidth(propertyCode, productProperties[propertyCode], locale),
            disableSortBy: !includes(
                ['codice_articolo', 'descrizione_articolo', 'descrizione_marca', 'prezzo_listino'],
                propertyCode
            ),
            Cell: ({ cell }) => {
                return (
                    <span
                        style={{
                            // whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                        className={cx({
                            'text-error blink':
                                propertyCode === 'stato' &&
                                get(cell, 'value.value.code') === 'IV006650',
                        })}
                        dangerouslySetInnerHTML={{
                            __html: getDetailValue(
                                cell.value,
                                productProperties[propertyCode],
                                locale,
                                productProperties,
                                i18n
                            ),
                        }}
                    />
                );
            },
        };
    });

    if (selectable) {
        baseColumns.unshift({
            Header: () => {
                return (
                    <CollectionControlsHeader
                        selectAllDisabled={isAnyFilterActive === false}
                        onSelectAll={onSelectAll}
                        onRemoveAll={onRemoveAll}
                    />
                );
            },
            width: 60,
            disableSortBy: true,
            accessor: 'id',
            Cell: ({ row }) => {
                return (
                    <span className="form-group">
                        <label
                            className="form-checkbox form-checkbox-inline"
                            style={{ top: 0, paddingRight: 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onSelectRow(row.original.id);
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={row.original.in_collection}
                                onClick={(e) => e.stopPropagation()}
                                onChange={() => {}}
                            />
                            <i className="form-icon" />
                        </label>
                    </span>
                );
            },
        });
    }

    return baseColumns;
}

export function DynamicTable({
    productProperties,
    items,
    isFetching,
    // apyPayload,
    i18n,
    tableColumns,
    isAnyFilterActive,
    selectable,
    sortBy,
    sorting,
    onSelectAll,
    onRemoveAll,
    onSelectRow,
    // width
}) {
    // const payload = {
    //     per_page: 40,
    //     page: 1,
    //     locale: 'it_IT',
    //     marca: '5f96f4eb-e841-41f2-85cf-3eb857def25a',
    //     product_tree: 'idrolab'
    // };

    /*const properties = [
        'sigla_marca',
        'codice_articolo',
        'descrizione_articolo',
        'unita_ordine',
        'classe_prodotto'
    ];*/

    const language = i18n._language;

    const locale = getLocaleFromLanguageCode(language);

    // console.log(locale);
    // console.log(productProperties);

    const columns = React.useMemo(
        () =>
            buildTableColumns(
                tableColumns,
                productProperties,
                locale,
                i18n,
                selectable,
                onSelectAll,
                onRemoveAll,
                isAnyFilterActive,
                onSelectRow
            ),
        [
            tableColumns,
            locale,
            productProperties,
            i18n,
            selectable,
            onSelectAll,
            onRemoveAll,
            isAnyFilterActive,
            onSelectRow,
        ]
    );

    // console.log(columns);

    // console.log('Loading: ' + isLoading);
    // console.log('Fetching: ' + isFetching);

    // if (isLoading) {
    //     return <h1>Loading...</h1>;
    // }

    // console.log(items);

    return (
        // <div
        //     style={{
        //         whiteSpace: 'nowrap',
        //         display: 'block',
        //         overflowX: 'auto',
        //         width
        //     }}
        // >
        <Table
            columns={columns}
            data={items}
            isFetching={isFetching}
            sortBy={sortBy}
            sorting={sorting}
        />
        // </div>
    );
}

function Table({ columns, data = [], isFetching, sortBy, sorting }) {
    // Use the state and functions returned from useTable to build your UI
    /*const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data
    }, useFlexLayout);*/

    // console.log(sorting);

    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);

    React.useEffect(() => {
        const tBody = document.getElementsByClassName('--tabulisk-tbody')[0];
        const tHead = document.getElementsByClassName('--tabulisk-thead')[0];

        const handleScroll = () => {
            const left = tBody.scrollLeft;

            tHead.scrollLeft = left;
        };

        if (tBody) {
            tBody.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tBody) {
                tBody.removeEventListener('scroll', handleScroll);
            }
        };
    });

    // const renderRowSubComponent = React.useCallback(({ row }) => {
    //     // console.log(row);
    //     return (
    //         <ArticleDetails
    //             flexLayout={true}
    //             open={true}
    //             // tdClass={isOdd(row.index + 1) ? 'bg-alternate-row' : ''}
    //             item={{
    //                 id: row.original.id,
    //                 metadata: {
    //                     immagine_prodotto: { url: get(row, 'original.immagine_prodotto.url') },
    //                 },
    //             }}
    //         />
    //     );
    // }, []);

    console.log(data);

    return (
        <Fragment>
            {showModal && (
                <Portal>
                    <ArticleDetailsModal
                        article={{ id: currentItem }}
                        onClose={() => {
                            setShowModal(false);
                            setCurrentItem(null);
                        }}
                        containerId={`article-detail-modal-${currentItem}`}
                    />
                </Portal>
            )}
            <DataTable
                data={isFetching ? [] : data}
                columns={columns}
                config={{
                    useFlex: true,
                    remote: true,
                    onToggleSortBy: (columnId) => {
                        // console.log(columnId);
                        sortBy(columnId);
                    },
                    // useExpanded: true,
                    // renderRowSubComponent,
                    sortableColumnTitle: null,
                    getRowProps(row) {
                        // console.log(row);

                        return {
                            className: isOdd(row.index + 1)
                                ? 'bg-alternate-row c-zoom-in'
                                : 'c-zoom-in',
                            onClick: () => {
                                console.log(row);
                                setShowModal(true);
                                setCurrentItem(row.original.id);
                            },
                        };
                    },
                    controlledStateHook: (tableState) => {
                        /* eslint-disable-next-line */
                        const sortBy = React.useMemo(
                            () => [{ id: sorting.sortBy, desc: sorting.sortDirection === 'desc' }],
                            /* eslint-disable-next-line */
                            [sorting]
                        );

                        // console.log(tableState);

                        /* eslint-disable-next-line */
                        return React.useMemo(() => {
                            return {
                                ...tableState,
                                sortBy,
                            };
                            /* eslint-disable-next-line */
                        }, [tableState, sortBy]);
                    },
                    noRowsMessage: isFetching ? (
                        <Loader />
                    ) : (
                        <div className="bg-gray">
                            <div className="empty">
                                <div className="empty-icon">
                                    <i className="icon icon-search" />
                                </div>
                                <p className="empty-title h5">
                                    <Trans id="article:count" values={{ count: 0 }} />
                                </p>
                                <p className="empty-subtitle">
                                    <Trans id="Use filters to re-search" />
                                </p>
                            </div>
                        </div>
                    ),
                }}
            />
        </Fragment>
    );
}

function mapStateToProps(state) {
    return {
        productProperties: selectors.getProductProperties(state),
        apyPayload: filterSelectors.getApiPayload(state),
        tableColumns: selectors.getTableColumns(state),
        isAnyFilterActive: filterSelectors.isAnyFilterActive(state),
    };
}

export default compose(connect(mapStateToProps), withI18n())(DynamicTable);
