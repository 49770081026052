import { call, put, /*fork,*/ select, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { hash, fromJS } from 'immutable';

import { actionTypes, actions } from '../actions/productClassesActions';
import { fetchItems } from '../actions/itemsActions';
import { actions as sectorActions } from '../actions/sectorsActions';
import { selectors } from '../reducers/productClassesReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { selectors as sectorsSelectors } from '../reducers/sectorsReducer';
// import { refreshFiltersValues } from './filtersSaga';
import { fetchEtimFeatures, fetchEtimFeatureValues } from '../api';
import { ETIM_CLASSIFICATION } from '../constants';

function* changeProductClassFilterSaga(action) {
    yield put(actions.resetProductClass());

    const classificationType = yield select(sectorsSelectors.getFilterValue);

    try {
        // if (action.payload === null) {
        //     yield fork(refreshFiltersValues);
        // } else {

        if (action.payload !== null) {
            yield put(actions.fetchProductClassAttributes());

            if (classificationType === ETIM_CLASSIFICATION) {
                yield put(sectorActions.setSelectedItemTree(action.payload.value));
            }
        } else {
            // TODO: mi resetta l'oggetto selezionato nell'albero anche se non dovrebbe (seleziono un gruppo diverso da quello della classe attuale)
            if (classificationType === ETIM_CLASSIFICATION) {
                const depth = yield select(sectorsSelectors.getSelectedTreeItemDepth);

                if (depth === 2) {
                    yield put(sectorActions.resetSelectedTreeItem());
                }
            }
        }

        // Fetcho Items solo DOPO aver modificato la classe nei filtri in maniera da non fetcharli con il valore precedente
        yield put(fetchItems());
    } catch (err) {
        // TODO: gestire errori
        console.error(err);
    }
}

function* fetchProductClassAttributesSaga(action) {
    try {
        yield put(actions.fetchProductClassAttributesStart());

        const apiPayload = yield select(filtersSelectors.getApiPayload);

        // yield fork(refreshFiltersValues, apiPayload);

        const res = yield call(fetchEtimFeatures, apiPayload);

        yield put(actions.fetchProductClassAttributesSuccess(res));
    } catch (err) {
        // TODO: gestire errori
        console.error(err);
    }
}

function* resetAllProductClassAttributesFiltersSaga() {
    // TODO: assicurarsi che questo avvenga sempre DOPO l'avvenuta modifica dello state da parte del relativo reducer
    yield delay(100);
    yield put(fetchItems());
}

function* fetchProductClassAttributeDataSaga(action) {
    const id = action.payload.id;

    const attribute = yield select(selectors.getProductClassAttribute, id);
    const apiPayload = yield select(filtersSelectors.getApiPayload);

    // Mi calcolo l'hash del payload
    const requestHash = hash(fromJS(apiPayload));
    const samehash = requestHash === attribute.lastRequestHash;

    // Non fetcho se sto gia fetchando o se è lo stesso hash dell'ultima richiesta eseguita correttamente
    if (attribute.isFetching === true || samehash) {
        return false;
    }

    yield put(actions.fetchProductClassAttributeDataStart(id));

    try {
        const res = yield call(fetchEtimFeatureValues, id, apiPayload);

        yield put(actions.fetchProductClassAttributeDataSuccess(id, res.data, requestHash));
    } catch (e) {
        yield put(actions.fetchProductClassAttributeDataFail(id, e));
    }
}

function* filterByProductClassAttributeSaga() {
    yield delay(100);
    yield put(fetchItems());
}

/* eslint import/no-anonymous-default-export: [2, {"allowArray": true}] */
export default [
    takeEvery(actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTES, fetchProductClassAttributesSaga),
    takeEvery(actionTypes.SET_PRODUCT_CLASS_VALUE, changeProductClassFilterSaga),
    takeEvery(
        actionTypes.RESET_PRODUCT_CLASS_ATTRIBUTES_FILTERS_ALL,
        resetAllProductClassAttributesFiltersSaga
    ),
    takeEvery(actionTypes.FETCH_PRODUCT_CLASS_ATTRIBUTE_DATA, fetchProductClassAttributeDataSaga),
    takeEvery(
        [
            actionTypes.SET_PRODUCT_CLASS_ATTRIBUTE_LOGIC,
            actionTypes.SET_PRODUCT_CLASS_ATTRIBUTE_NUMERIC,
            actionTypes.TOGGLE_PRODUCT_CLASS_ATTRIBUTE_FILTER,
            actionTypes.RESET_PRODUCT_CLASS_ATTRIBUTE_FILTERS,
        ],
        filterByProductClassAttributeSaga
    ),
];
