import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import NewWindow from 'react-new-window';
import cx from 'classnames';
import { Trans, withI18n } from '@lingui/react';
// import { Element } from 'react-scroll';
import { Manager, Reference, Popper } from 'react-popper';
import { FaExpandArrowsAlt, FaTimes, FaPercent } from 'react-icons/fa';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import transform from 'lodash/transform';
import find from 'lodash/find';
import last from 'lodash/last';
import xor from 'lodash/xor';
import get from 'lodash/get';

import ArticleRecap from './ArticleRecap';
import ArticleDetailsCategoryTab from './ArticleDetailsCategoryTab';
import ArticleDetailsModal from './ArticleDetailsModal';
// import ErrorBoundary from './utils/ErrorBoundary';
// import ItemDataCategory from './ItemDataCategory';
import { getEtimImageUrl, getAssetThumbUrl, getAssetURL } from '../api';
import { getCategoryButtonStatus, getDescrizioneMarcaFromArticolo } from '../helpers';
import noPhotoImg from '../images/no-photo.png';
import { selectors } from '../reducers/appReducer';
import { ARTICLE_DETAIL_SECTIONS } from '../constants';
import { getLocaleFromLanguageCode } from '../intl-helpers';

const categories = Object.values(ARTICLE_DETAIL_SECTIONS);

const orderedCategoriesKeys = sortBy(categories, 'order').map((c) => c.key);

function getScontoAumentoString(details) {
    let entries = [];

    for (let i = 1; i < 6; i++) {
        const value = get(details, `varie.sconto_aumento_${i}.value`, 0);

        if (value && value !== 0) {
            const sign = value > 0 ? '+' : '-';
            entries.push(`${sign}${value}%`);
        }
    }

    return entries.join(', ');
}

function ModalScontoAumento({ onClose, details }) {
    const scontoAumentoString = getScontoAumentoString(details);

    return (
        <div className="bg-white drop-shadow-2xl rounded-lg border border-gray-500">
            <div className="modal-header">
                <div className="modal-title flex h6">
                    <div className="grow">
                        <Trans id="brand status" />{' '}
                        {get(details, 'identificazione.descrizione_marca.value')}
                    </div>
                    <span
                        className="btn btn-clear float-right"
                        aria-label="Close"
                        onClick={onClose}
                    />
                </div>
            </div>
            <div className="modal-body border-t">
                <div className="content px-1 pb-2">
                    <Trans id="uncalculated percentage changes" />:{' ' + scontoAumentoString}
                </div>
            </div>
        </div>
    );
}

export class ArticleDetailsBody extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeCategories: [...props.initialCategories],
            showPrintWindow: false,
            showPopper: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeCategories.length < this.state.activeCategories.length) {
            this.props.onSelectTab(last(this.state.activeCategories));
        }
    }

    handleTabClick = (key) => {
        this.setState((prevState) => ({
            activeCategories: xor(prevState.activeCategories, [key]),
        }));
    };

    getActiveCategoriesOrdered() {
        return orderedCategoriesKeys.reduce((result, key) => {
            if (includes(this.state.activeCategories, key)) {
                result.push(key);
            }

            return result;
        }, []);
    }

    getValidCategories() {
        const status = getCategoryButtonStatus(this.props.details);

        return transform(
            status,
            (res, v, k) => {
                if (v === true) {
                    res.push(k);
                }

                return res;
            },
            []
        );
    }

    toggleAllCategories = () => {
        const valid = this.getValidCategories();

        if (this.state.activeCategories.length !== valid.length) {
            // const keys = categories.map(c => c.key);

            this.setState({
                activeCategories: valid,
            });
        } else {
            this.setState({
                activeCategories: [],
            });
        }
    };

    getCanExpandAll() {
        const valid = this.getValidCategories();

        return this.state.activeCategories.length !== valid.length;
    }

    getCategory(key) {
        // const { etimClassDetails, productInfoMap } = this.props;

        const category = find(categories, {
            key,
        });

        // if (key !== 'caratteristiche') {
        //     const categoryInfo = productInfoMap[key];

        //     category.info = categoryInfo || {};
        // } else {
        //     category.info = etimClassDetails;
        // }

        return category;
    }

    showPrintWindow = () => {
        this.setState({
            showPrintWindow: true,
        });
    };

    hidePrintWindow = () => {
        this.setState({
            showPrintWindow: false,
        });
    };

    getPrintWindowTitle() {
        return `${this.getMarcaArticolo()} - ${this.getCodiceArticolo()} - ${this.getDescrizioneArticolo()}`;
    }

    getMarcaArticolo() {
        return getDescrizioneMarcaFromArticolo(
            this.props.details,
            getLocaleFromLanguageCode(this.props.language)
        );
    }

    getCodiceArticolo() {
        return get(this.props.details, 'identificazione.codice_articolo.value');
    }

    getDescrizioneArticolo() {
        return get(
            this.props.details,
            `identificazione.descrizione_articolo.value.${getLocaleFromLanguageCode(
                this.props.language
            )}`
        );
    }

    /*getArticleImgSrc() {
        const explicit = get(this.props.article, 'immagine_prodotto.url');

        if (explicit) {
            return getAssetURL(explicit);
        }

        if (this.props.details.assets.immagine && this.props.details.assets.immagine.length > 0) {
            const fromAssets = find(this.props.details.assets.immagine, img => {
                let found = false;

                img.asset_classes.forEach(klass => {
                    if (klass.code === 'immagine_prodotto') {
                        found = true;
                    }
                });

                return found;
            });

            if (fromAssets) {
                return getAssetURL(fromAssets.url);
            }
        }

        return noPhotoImg;
    }*/

    getItemImage(item) {
        // console.log(item);

        if (
            item.metadata &&
            item.metadata.immagine_prodotto &&
            item.metadata.immagine_prodotto.url
        ) {
            // return getAssetURL(item.metadata.immagine_prodotto.url);
            return getAssetThumbUrl(item.metadata.immagine_prodotto.url);
        }

        const etimClass = item.etim;

        if (etimClass && etimClass.name) {
            if (etimClass.value) {
                return getEtimImageUrl(etimClass.value.name);
            } else {
                // console.log(etimClass);
                return null;
            }
        }

        return null;
    }

    getScontoAumento() {
        let sum = 0;

        for (let i = 1; i < 6; i++) {
            const value = get(this.props.details, `varie.sconto_aumento_${i}.value`, 0);

            if (value) {
                sum += value;
            }
        }

        return sum !== 0;
    }

    togglePopper = () => {
        return this.setState({
            showPopper: !this.state.showPopper,
        });
    };

    render() {
        const {
            article,
            details,
            isPreview,
            productProperties,
            etimClassDetails,
            scrollContainer,
            i18n,
            language,
        } = this.props;

        // console.warn(propductProperties);

        const { activeCategories, showPrintWindow, showPopper } = this.state;

        const canExpandAll = this.getCanExpandAll();

        const shouldShowBottomMargin = window.innerWidth < 640;

        return (
            <Fragment>
                <Manager>
                    {showPrintWindow && (
                        <NewWindow
                            title={this.getPrintWindowTitle()}
                            onUnload={this.hidePrintWindow}
                            features={{
                                menubar: 1,
                                width: 1024,
                            }}
                        >
                            <ArticleDetailsModal
                                article={article}
                                details={details}
                                containerId={`article-detail-modal-${article.id}`}
                                initialCategories={activeCategories}
                                productProperties={productProperties}
                                etimClassDetails={etimClassDetails}
                                language={language}
                                isPreview={true}
                            />
                        </NewWindow>
                    )}
                    <div
                        className="grid grid-cols-12"
                        style={{ marginBottom: shouldShowBottomMargin ? '80px' : '0' }}
                    >
                        <div className="col-span-12 md:col-span-9">
                            <ArticleRecap
                                language={language}
                                item={article}
                                details={details}
                                productProperties={productProperties}
                            />
                        </div>
                        <div className="col-span-12 md:col-span-3" style={{ position: 'relative' }}>
                            <div
                                className="article-details-img-wrapper"
                                style={isPreview ? { height: '235px' } : { maxHeight: '235px' }}
                            >
                                {/* {this.getItemImage(details) ? ( */}
                                {get(details, 'metadata.immagine_prodotto.url') ? (
                                    <div
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            position: 'relative',
                                            // background: `url("${this.getItemImage(details)}")`,
                                            background: `url("${getAssetURL(
                                                details.metadata.immagine_prodotto.url
                                            )}")`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center',
                                        }}
                                    />
                                ) : (
                                    <img src={noPhotoImg} style={{ margin: '0 auto' }} alt="" />
                                )}
                            </div>
                            {isPreview === false && (
                                <div className="article-ftp-button-holder text-right">
                                    {this.getScontoAumento() && (
                                        <Reference>
                                            {({ ref }) => (
                                                <button
                                                    onClick={this.togglePopper}
                                                    ref={ref}
                                                    className="btn mr-1"
                                                >
                                                    <FaPercent />
                                                </button>
                                            )}
                                        </Reference>
                                    )}
                                    <button
                                        className={cx('btn tooltip', {
                                            'btn-primary': !canExpandAll,
                                        })}
                                        onClick={this.toggleAllCategories}
                                        data-tooltip={
                                            canExpandAll
                                                ? i18n._('sections:open:all')
                                                : i18n._('sections:close:all')
                                        }
                                    >
                                        {canExpandAll ? (
                                            <FaExpandArrowsAlt style={{ verticalAlign: '-10%' }} />
                                        ) : (
                                            <FaTimes style={{ verticalAlign: '-10%' }} />
                                        )}
                                    </button>{' '}
                                    <button
                                        className="btn btn-success"
                                        onClick={this.showPrintWindow}
                                    >
                                        <i className="icon icon-download" />{' '}
                                        <span className="text-uppercase">
                                            <Trans id="Pdf Sheet" />
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                        {/* {isPreview === false && ( */}
                        <ArticleDetailsCategoryTab
                            onTabClick={this.handleTabClick}
                            activeCategories={activeCategories}
                            details={details}
                            scrollOnClick={true}
                            scrollContainer={scrollContainer}
                            productProperties={productProperties}
                            etimClassDetails={etimClassDetails}
                            item={article}
                            language={language}
                        />
                        {/* )} */}
                        {/* <div className="col-span-12">
                            <ErrorBoundary>
                                {this.getActiveCategoriesOrdered().map((key) => {
                                    const category = this.getCategory(key);

                                    return (
                                        <Element key={key} name={`category-${key}-${details.id}`}>
                                            <ItemDataCategory
                                                productProperties={productProperties}
                                                etimClassDetails={etimClassDetails}
                                                item={article}
                                                details={details}
                                                category={category}
                                                language={language}
                                            />
                                        </Element>
                                    );
                                })}
                            </ErrorBoundary>
                        </div> */}
                    </div>
                    <Popper
                        // innerRef={(node) => this.popperNode = node}
                        placement="top"
                        // modifiers={[{ name: 'arrow' }]}
                        strategy="fixed"
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ]}
                    >
                        {(props) => {
                            return showPopper ? (
                                <div
                                    ref={props.ref}
                                    style={props.style}
                                    data-placement={props.placement}
                                >
                                    <ModalScontoAumento
                                        onClose={this.togglePopper}
                                        details={this.props.details}
                                    />
                                    {/* <div data-popper-arrow className="popper-arrow" ref={props.arrowProps.ref} style={props.arrowProps.style} /> */}
                                </div>
                            ) : null;
                        }}
                    </Popper>
                </Manager>
            </Fragment>
        );
    }
}

ArticleDetailsBody.defaultProps = {
    onSelectTab: () => {},
};

const mapStateToProps = (state, ownProps) => {
    return {
        productProperties: selectors.getProductProperties(state),
        language: selectors.getLanguage(state),
    };
};

const ConnectedArticleDetailsBody = connect(mapStateToProps)(ArticleDetailsBody);

export default withI18n()(ConnectedArticleDetailsBody);
