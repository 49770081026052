import React, { Component } from 'react';
import { withI18n } from '@lingui/react';
// import { scroller } from 'react-scroll';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import cx from 'classnames';
import * as Accordion from '@radix-ui/react-accordion';
import { FiPlus, FiX } from 'react-icons/fi';

import * as c from '../constants';
import ItemDataCategory from './ItemDataCategory';
import ErrorBoundary from './utils/ErrorBoundary';
import { getCategoryButtonStatus } from '../helpers';
import { getLocaleFromLanguageCode } from '../intl-helpers';

const categories = Object.values(c.ARTICLE_DETAIL_SECTIONS);

export class ArticleDetailsCategoryTab extends Component {
    // handleClick = (key) => {
    //     const { onTabClick, scrollOnClick, scrollContainer, details } = this.props;

    //     onTabClick(key);

    //     if (scrollOnClick) {
    //         let scrollOptions = {
    //             duration: 500,
    //             delay: 50,
    //             smooth: 'easeInOutQuint',
    //         };

    //         if (scrollContainer) {
    //             scrollOptions.containerId = scrollContainer;

    //             // Per un bug (???) di react-new-window gli script del contenuto del popup vengono eseguiti con il contesto del
    //             // window del parent, quindi quando cerco il container non lo trovo perché si trova fisicamente in un
    //             // altro document. Potrei con qualche hack ottenere il contesto della finestra di popup ma non saprei come passarla
    //             // a react-scroll dato che vuole la stringa dello scrollContainer e non l'elemento stesso...
    //             // vedi: https://github.com/rmariuzzo/react-new-window/issues/58
    //             // FIXME: forse posso provare a passare il container direttamente come nodo HTML,
    //             // vedi qui: https://github.com/fisshy/react-scroll/blob/master/modules/mixins/scroller.js#L45
    //             const scrollContainerEl = document.getElementById(scrollContainer);

    //             if (scrollContainerEl === null) {
    //                 console.log(
    //                     `Warning! Container with id "${scrollContainer}" not found, skipping scroll request...`
    //                 );
    //                 return false;
    //             }
    //         }

    //         // Per dare tempo all'elemento di essere renderizzato
    //         setTimeout(() => {
    //             scroller.scrollTo(`category-${key}-${details.id}`, scrollOptions);
    //         }, 100);
    //     }
    // };

    render() {
        const {
            activeCategories,
            details,
            productProperties,
            etimClassDetails,
            article,
            language,
            i18n,
        } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        // console.log(this.props);

        // console.log(categoryButtonStatus);

        let extraCategories = [];

        // console.log(details);
        // console.log(productProperties);

        const categoryKeys = categories.map((c) => c.key);

        forOwn(details, (v, k) => {
            if (!includes(categoryKeys, k) && !includes(c.EXTRA_CATEGORIES_BLACKLIST_KEYS, k)) {
                if (Object.keys(v).length > 0) {
                    extraCategories.push({
                        key: k,
                        order: 99,
                        type: 'standard',
                    });
                }
            }
        });

        const finalCategories = [].concat(...categories).concat(...extraCategories);

        const categoryButtonStatus = getCategoryButtonStatus(details);

        return (
            <div className="col-span-12">
                {/* <div className="col-span-12">
                    {sortBy(categories, 'order').map((category) => (
                        <div className="article-category-tab-btn" key={category.key}>
                            <button
                                className={cx('btn', 'btn-block', 'btn-article-detail-section', {
                                    'btn-primary': includes(activeCategories, category.key),
                                    disabled: categoryButtonStatus[category.key] === false,
                                })}
                                onClick={this.handleClick.bind(this, category.key)}
                            >
                                <Trans id={category.shortLabel} />
                            </button>
                        </div>
                    ))}
                </div> */}
                <Accordion.Root type="multiple" collapsible={true} value={activeCategories}>
                    {sortBy(finalCategories, 'order').map((category) => {
                        const isOpen = includes(activeCategories, category.key);
                        const isDisabled = categoryButtonStatus[category.key] === false;

                        return (
                            <Accordion.Item
                                value={category.key}
                                key={category.key}
                                disabled={isDisabled}
                            >
                                <Accordion.Header asChild={true}>
                                    <div
                                        className={cx('text-l border-b select-none', {
                                            'text-primary': !isDisabled,
                                            'text-gray-500': isDisabled,
                                        })}
                                    >
                                        <Accordion.Trigger
                                            asChild={true}
                                            onClick={() =>
                                                !isDisabled && this.props.onTabClick(category.key)
                                            }
                                        >
                                            <div
                                                className={cx('flex items-center', {
                                                    'cursor-pointer': !isDisabled,
                                                })}
                                            >
                                                {isOpen ? <FiX /> : <FiPlus />}
                                                <span className="ml-3">
                                                    {get(
                                                        productProperties,
                                                        `${category.key}.label.${locale}`,
                                                        i18n._(category.label)
                                                    )}
                                                    {/* <Trans id={category.label} /> */}
                                                </span>
                                            </div>
                                        </Accordion.Trigger>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Content>
                                    <ErrorBoundary>
                                        <ItemDataCategory
                                            productProperties={productProperties}
                                            etimClassDetails={etimClassDetails}
                                            item={article}
                                            details={details}
                                            category={category}
                                            language={language}
                                        />
                                    </ErrorBoundary>
                                </Accordion.Content>
                            </Accordion.Item>
                        );
                    })}
                </Accordion.Root>
            </div>
        );
    }
}

ArticleDetailsCategoryTab.defaultProps = {
    scrollOnClick: false,
};

export default withI18n()(ArticleDetailsCategoryTab);
