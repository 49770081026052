import React, { Component } from 'react';
import { connect } from 'react-redux';

import ArticleTable from '../components/ArticleTable';
import { selectors } from '../reducers/itemsReducer';
import { selectors as filtersSelectors } from '../reducers/filtersReducer';
import { selectors as collectionsSelectors } from '../reducers/collectionsReducer';
// import { selectors as userSelectors } from '../reducers/userReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import { actions } from '../actions/itemsActions';
import { actions as collectionsActions } from '../actions/collectionsActions';
import { actions as appActions } from '../actions/appActions';
import * as constants from '../constants';

export class ArticleTableContainer extends Component {
    render() {
        return <ArticleTable {...this.props} />;
    }
}

function mapStateToProps(state) {
    const isCollectionEngaged = collectionsSelectors.getEngagedCollection(state) !== null;

    return {
        isFetching: selectors.getIsFetching(state),
        productProperties: appSelectors.getProductProperties(state),
        items: isCollectionEngaged
            ? selectors.getItemsWithCollections(state)
            : selectors.getItems(state),
        customItems: isCollectionEngaged
            ? selectors.getCustomItemsWithCollections(state).toJS()
            : selectors.getCustomItems(state).toJS(),
        total: selectors.getItemsTotal(state),
        serverTotal: selectors.getItemsServerTotal(state),
        q: filtersSelectors.getFilter(state, constants.FULL_TEXT_FILTER).value,
        sorting: selectors.getSorting(state),
        currentPage: selectors.getCurrentPage(state),
        pages: selectors.getPagesTotal(state),
        lastSearchHash: selectors.getLastSearchHash(state),
        areRowsSelectable: isCollectionEngaged,
        // user: userSelectors.getUser(state),
        language: appSelectors.getLanguage(state),
        isAnyFilterActive: filtersSelectors.isAnyFilterActive(state),
        defaultTableView: appSelectors.getDefaultTableView(state),
        currentViewType: appSelectors.getCurrentViewType(state),
        listiniFilter: filtersSelectors.getFilter(state, constants.LISTINI_FILTER),
        brandFilter: filtersSelectors.getFilter(state, constants.BRAND_FILTER)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sortBy(attr) {
            dispatch(actions.sortBy(attr));
        },
        onPageChange(page) {
            dispatch(actions.changePage(page));
        },
        onSelectRow(id) {
            dispatch(collectionsActions.toggleItemFromEngagedCollection(id));
        },
        onSelectAll() {
            dispatch(collectionsActions.addAllItemsToCollection());
        },
        onRemoveAll() {
            dispatch(collectionsActions.removePagedItemsFromCollection());
        },
        onSetViewType(viewType) {
            dispatch(appActions.changeCurrentTableView(viewType));
        },
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTableContainer);
