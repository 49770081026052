import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import { withI18n, i18nMark, Trans } from '@lingui/react';
import { toast } from 'react-toastify';

import LogoUploader from './form/LogoUploader';
import InputField from './form/InputField';
import SubmitButton from './form/SubmitButton';
import { fetchPreferences, updatePreference } from '../api';
import 'filepond/dist/filepond.min.css';

function validate(values) {
    const errors = {};

    const hexRe = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

    if (values.appColor) {
        if (hexRe.exec(values.appColor) === null) {
            errors.appColor = i18nMark('color:format:invalid');
        }
    }

    if (values.alternateRowColor) {
        if (hexRe.exec(values.alternateRowColor) === null) {
            errors.alternateRowColor = i18nMark('color:format:invalid');
        }
    }

    return errors;
}

export class CustomizationPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            appColor: '',
            appName: '',
            alternateRowColor: '',
            // serverVersion: null,
        };
    }

    async componentDidMount() {
        const res = await fetchPreferences();

        // const vRes = await getServerVersion();

        let newState = {
            // serverVersion: vRes.data,
        };

        res.data.forEach((preference) => {
            if (preference.name === 'appColor') {
                newState.appColor = preference.value;
            } else if (preference.name === 'appName') {
                newState.appName = preference.value;
            } else if (preference.name === 'alternateRowColor') {
                newState.alternateRowColor = preference.value;
            }
        });

        this.setState(newState);
    }

    onSubmit = async (data) => {
        await updatePreference('appName', data.appName || '');
        await updatePreference('appColor', data.appColor || '');
        await updatePreference('alternateRowColor', data.alternateRowColor || '');

        this.setState(data);

        toast(this.props.i18n._('preferences:update:success'), {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS,
            autoClose: 10000,
        });
    };

    render() {
        // const { serverVersion } = this.state;

        return (
            <div className="grid grid-cols-12 mx-2">
                <div className="col-span-12 lg:col-span-4 lg:col-start-5">
                    <h1 className="text-primary">{this.props.i18n._('general preferences')}</h1>
                    <Form
                        onSubmit={this.onSubmit}
                        validate={validate}
                        initialValues={this.state}
                        render={({
                            handleSubmit,
                            pristine,
                            hasValidationErrors,
                            submitError,
                            submitting,
                            errors,
                            touched,
                            ...rest
                        }) => {
                            // console.warn(rest);

                            return (
                                <form
                                    ref={(form) => (this.form = form)}
                                    onSubmit={handleSubmit}
                                    className="form-horizontal text-dark"
                                >
                                    <div className="mb-2">
                                        <Field
                                            name="appName"
                                            component={InputField}
                                            layout="horizontal"
                                            placeholder={i18nMark('appName')}
                                            className="form-input input-lg"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Field
                                            name="appColor"
                                            component={InputField}
                                            layout="horizontal"
                                            placeholder={i18nMark('appColor')}
                                            className="form-input input-lg"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Field
                                            name="alternateRowColor"
                                            component={InputField}
                                            layout="horizontal"
                                            placeholder={i18nMark('alternateRowColor')}
                                            className="form-input input-lg"
                                        />
                                    </div>
                                    <SubmitButton submitting={submitting} disabled={pristine}>
                                        <Trans id="save" />
                                    </SubmitButton>
                                </form>
                            );
                        }}
                    />
                    <h1 className="text-primary mt-4">{this.props.i18n._('images management')}</h1>
                    <LogoUploader
                        type="favicon"
                        height={24}
                        helperText={`${this.props.i18n._('suggested:size')}: 64x64px`}
                    />
                    <LogoUploader
                        type="logo"
                        name="full"
                        height={50}
                        helperText={`${this.props.i18n._('suggested:size')}: 240x50px`}
                    />
                    <LogoUploader
                        type="logo"
                        name="header"
                        height={40}
                        helperText={`${this.props.i18n._('suggested:size')}: 40x40px`}
                        withBackground={true}
                    />
                    {/* <hr className="my-4" />
                    <div className="text-right">
                        Versione <b>{process.env.REACT_APP_VERSION}</b>{' '}
                        {serverVersion && (
                            <span>
                                (Server <b>{serverVersion}</b>)
                            </span>
                        )}
                    </div> */}
                </div>
            </div>
        );
    }
}

export default withI18n()(CustomizationPanel);
