import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withI18n, Trans } from '@lingui/react';
import Swal from 'sweetalert2';
import { removeBrandProducts } from '../api';
import { toast } from 'react-toastify';
import { parse, format } from 'date-fns';
import { BRAND_PROCESS_IMPORT_PRODUCTS } from '../constants';

function localizeDate(date) {
    if (!date) {
        return '-';
    }

    return format(parse(date), 'DD/MM/YYYY HH:mm:ss');
}

export class ProcessDetailsModal extends Component {
    handleDelete = () => {
        const { i18n, process } = this.props;

        Swal.fire({
            icon: 'warning',
            text: i18n._('brand:remove:confirm', {
                brand: process.description,
            }),
            showCancelButton: true,
            confirmButtonText: i18n._('confirm'),
            confirmButtonColor: '#007bff',
            cancelButtonText: i18n._('cancel'),
        }).then(async (res) => {
            if (res.value === true) {
                await removeBrandProducts(process.code);

                toast(i18n._('brand:remove:queued'), {
                    position: 'bottom-right',
                    type: toast.TYPE.SUCCESS,
                });

                this.props.onDeleteSuccess();
                this.props.onClose();
            }
        });
    };

    render() {
        const { onClose, process, i18n } = this.props;

        console.log(process);

        return (
            <div className="modal active" id="user-details-modal-id">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container" style={{ maxWidth: '800px' }}>
                    <div className="modal-header">
                        <div className="modal-title text-center h5">
                            {process.description}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body" id="user-details-modal">
                        <div className="content">
                            <div className="container">
                                <div className="columns">
                                    <div className="column col-4 text-right">
                                        <Trans id="acronym" />:
                                    </div>
                                    <div className="column col-8 text-bold">{process.code}</div>
                                    <div className="column col-4 text-right">
                                        <Trans id="description" />:
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {process.description}
                                    </div>
                                    {process.operation === BRAND_PROCESS_IMPORT_PRODUCTS && (
                                        <>
                                            <div className="column col-4 text-right">
                                                Num. totale articoli:
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.articles_number}
                                            </div>
                                        </>
                                    )}
                                    {process.errorReason && (
                                        <>
                                            <div className="column col-4 text-right">
                                                <Trans id="error:reason" />:
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.errorReason}
                                            </div>
                                        </>
                                    )}
                                    <div className="column col-4 text-right">
                                        {process.operation === BRAND_PROCESS_IMPORT_PRODUCTS
                                            ? i18n._('date:last:import')
                                            : i18n._('date:brand:removal')}
                                        {':'}
                                    </div>
                                    <div className="column col-8 text-bold">
                                        {localizeDate(process.date)}
                                    </div>
                                    {process.operation === BRAND_PROCESS_IMPORT_PRODUCTS && (
                                        <>
                                            <div className="column col-4 text-right">
                                                <Trans id="article:read" />
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.info.read}
                                            </div>
                                            <div className="column col-4 text-right">
                                                <Trans id="article:created" />
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.info.created}
                                            </div>
                                            <div className="column col-4 text-right">
                                                <Trans id="article:removed" />
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.info.removed}
                                            </div>
                                            <div className="column col-4 text-right">
                                                <Trans id="article:updated" />
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.info.updated}
                                            </div>
                                            <div className="column col-4 text-right">
                                                <Trans id="article:untouched" />
                                            </div>
                                            <div className="column col-8 text-bold">
                                                {process.info.untouched}
                                            </div>
                                        </>
                                    )}
                                    {process.operation === BRAND_PROCESS_IMPORT_PRODUCTS &&
                                        process.status === 'completed' && (
                                            <div className="column col-12 text-right">
                                                <button
                                                    className="btn btn-error"
                                                    onClick={this.handleDelete}
                                                >
                                                    <Trans id="brand:remove" />
                                                </button>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProcessDetailsModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    process: PropTypes.object.isRequired,
};

export default withI18n()(ProcessDetailsModal);
