import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DropdownList from './DropdownList';
import DropdownMultiple from './DropdownMultiple';

class DropdownListFilterWrapper extends Component {
    getItems() {
        const { filter, validFilterOptions } = this.props;

        if (validFilterOptions === null) {
            return filter.options
                .sortBy(o =>
                    this.props.itemToSortString
                        ? this.props.itemToSortString(o).toLowerCase()
                        : this.props.itemToString(o).toLowerCase()
                )
                .toJS();
        }

        return filter.options
            .filter(o => validFilterOptions.includes(o.value))
            .sortBy(o =>
                this.props.itemToSortString
                    ? this.props.itemToSortString(o).toLowerCase()
                    : this.props.itemToString(o).toLowerCase()
            )
            .toJS();
    }

    render() {
        const {
            filter,
            onChange,
            placeholder,
            validFilterOptions,
            disabled,
            multiple,
            ...rest
        } = this.props;

        if (multiple) {
            return (
                <DropdownMultiple
                    {...rest}
                    items={this.getItems()}
                    placeholder={placeholder}
                    selectedItem={filter.value.toJS()}
                    disabled={disabled || !filter.enabled || this.getItems().length === 0}
                    onChange={item => onChange(item)}
                    onLoad={() => {}}
                    clearable={true}
                    showPlaceholder={true}
                />
            );
        }

        return (
            <DropdownList
                {...rest}
                items={this.getItems()}
                placeholder={placeholder}
                selectedItem={filter.value}
                disabled={disabled || !filter.enabled || this.getItems().length === 0}
                onChange={item => onChange(item)}
            />
        );
    }
}

DropdownListFilterWrapper.propTypes = {
    disabled: PropTypes.bool,
    filter: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    validFilterOptions: PropTypes.object,
    multiple: PropTypes.bool
};

DropdownListFilterWrapper.defaultProps = {
    disabled: false,
    validFilterOptions: null,
    multiple: false
};

export default DropdownListFilterWrapper;
