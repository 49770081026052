import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'react-image';
import { Trans, withI18n } from '@lingui/react';
// import format from 'date-fns/format';
import Highlighter from 'react-highlight-words';
// import get from 'lodash/get';
import find from 'lodash/find';

// import ArticleDetailsClose from './ArticleDetailsClose';
import LimitText from './utils/LimitText';
import { getEtimImageUrl, getAssetThumbUrl, getAssetURL } from '../api';
// import { UM_LABELS } from '../constants';
import noPhotoImg from '../images/no-photo.png';
import { getLocaleFromLanguageCode } from '../intl-helpers';
import { getDetailValue } from '../utils/dataFormaters';

function getProperty(code, productProperties) {
    return productProperties[code];
}

function getPropertyValue(code, itemProps) {
    return find(itemProps, (prop) => prop.property === code);
}
class RowDetailed extends Component {
    // getValiditaPrezzo() {
    //     const { item } = this.props;

    //     if (item.prezzo_attuale && item.prezzo_attuale.data_inizio_validita_prezzo) {
    //         return format(item.prezzo_attuale.data_inizio_validita_prezzo.date, 'DD/MM/YYYY');
    //     }

    //     return null;
    // }

    getPropertyDetailValue(code) {
        const { item, language, productProperties, i18n } = this.props;

        const locale = getLocaleFromLanguageCode(language);

        return getDetailValue(
            getPropertyValue(code, item.children),
            getProperty(code, productProperties),
            locale,
            productProperties,
            i18n
        );
    }

    getItemImage(item) {
        if (
            item.metadata &&
            item.metadata.immagine_prodotto &&
            item.metadata.immagine_prodotto.url
        ) {
            return getAssetThumbUrl(item.metadata.immagine_prodotto.url);
        }

        const etimClass = find(item.children, { property: 'etim_class' });

        if (etimClass && etimClass.value && etimClass.value.name) {
            return getEtimImageUrl(etimClass.value.name);
        }

        return noPhotoImg;
    }

    render() {
        const {
            // bg,
            item,
            // onCloseButtonClick,
            // showDetails,
            q,
            selectable,
            onSelect,
            availableProps,
            // productProperties,
        } = this.props;

        // console.log(availableProps);
        // console.log(productProperties);

        const prezzo = this.getPropertyDetailValue('prezzo_listino');
        const validita_prezzo = this.getPropertyDetailValue('data_inizio_validita_prezzo');

        // console.warn(validita_prezzo);

        return (
            <Fragment>
                <td>
                    <div className="table-img-preview">
                        <Img
                            // src={this.getItemImage(item)}
                            src={
                                item.metadata && item.metadata.immagine_prodotto
                                    ? getAssetURL(item.metadata.immagine_prodotto.url)
                                    : noPhotoImg
                            }
                            className="img-responsive m-a"
                            loader={<div className="rounded bg-secondary table-img-preview" />}
                            style={{
                                maxHeight: '145px',
                            }}
                        />
                    </div>
                </td>
                {selectable && (
                    <td>
                        <span className="form-group">
                            <label
                                className="form-checkbox form-checkbox-inline"
                                style={{ top: '-47px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onSelect(item.id);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={item.in_collection}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={() => {}}
                                />
                                <i className="form-icon" />
                            </label>
                        </span>
                    </td>
                )}
                <td colSpan="6">
                    <div>
                        <span className="text-large">
                            <Highlighter
                                searchWords={[q]}
                                textToHighlight={
                                    this.getPropertyDetailValue('codice_articolo') || ''
                                }
                            />
                        </span>
                        <br />
                        <span className="text-uppercase">
                            <Highlighter
                                searchWords={[q]}
                                textToHighlight={
                                    this.getPropertyDetailValue('descrizione_articolo') || ''
                                }
                            />
                        </span>
                    </div>
                    <div className="columns" style={{ marginTop: 30 }}>
                        <div className="column col-3">
                            {availableProps.includes('descrizione_marca') && (
                                <>
                                    <span className="text-label text-uppercase">
                                        <Trans id="brand" />
                                    </span>
                                    <br />
                                    <LimitText
                                        limit={20}
                                        text={this.getPropertyDetailValue('descrizione_marca')}
                                    />
                                </>
                            )}
                        </div>
                        <div className="column col-3">
                            {availableProps.includes('serie') && (
                                <>
                                    <span className="text-label text-uppercase">
                                        <Trans id="series" />
                                    </span>
                                    <br />
                                    <LimitText
                                        limit={23}
                                        text={this.getPropertyDetailValue('serie')}
                                    />
                                </>
                            )}
                        </div>
                        <div className="column col-3">
                            {availableProps.includes('linee') && (
                                <>
                                    <span className="text-label text-uppercase">
                                        <Trans id="line" />
                                    </span>
                                    <br />
                                    <LimitText
                                        limit={23}
                                        text={this.getPropertyDetailValue('linea')}
                                    />
                                </>
                            )}
                        </div>
                        <div className="column col-3">
                            {availableProps.includes('modello') && (
                                <>
                                    <span className="text-label text-uppercase">
                                        <Trans id="model" />
                                    </span>
                                    <br />
                                    <LimitText
                                        limit={23}
                                        text={this.getPropertyDetailValue('modello')}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </td>
                <td>
                    <div className="container">
                        <div className="table-cell-grid">
                            <div className="text-center">
                                {availableProps.includes('unita_contenuta') && (
                                    <>
                                        <span className="text-label text-uppercase">
                                            <Trans id="um" />
                                        </span>
                                        <br />
                                        <span
                                            className="tooltip"
                                            data-tooltip={this.getPropertyDetailValue(
                                                'unita_ordine'
                                            )}
                                        >
                                            {this.getPropertyDetailValue('unita_contenuta')}
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="table-cell-grid">
                            <div className="text-center pt-2">
                                {availableProps.includes('quantita_contenuta') && (
                                    <>
                                        <span className="text-label text-uppercase">
                                            <Trans id="conf" />
                                        </span>
                                        <br />
                                        {this.getPropertyDetailValue('quantita_contenuta')}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </td>
                <td className="text-right">
                    <Fragment>
                        <span className="table-price-lg">{prezzo && `€ ${prezzo}`}</span>
                        <br />
                        {validita_prezzo && (
                            <span className="table-date-sm">
                                <Fragment>
                                    <Trans id="from" /> {validita_prezzo}
                                </Fragment>
                            </span>
                        )}
                    </Fragment>
                    {/* {showDetails && <ArticleDetailsClose bg={bg} onClick={onCloseButtonClick} />} */}
                </td>
            </Fragment>
        );
    }
}

RowDetailed.propTypes = {
    bg: PropTypes.string,
    index: PropTypes.number,
    onCloseButtonClick: PropTypes.func,
    showDetails: PropTypes.bool,
};

export default withI18n()(RowDetailed);
