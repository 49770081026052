import React, { Component } from 'react';
// import InfiniteTree from 'react-infinite-tree';
import { withI18n, i18nMark, Trans } from '@lingui/react';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
// import { Record, List, Seq } from 'immutable';
// import TreeUtils from 'immutable-treeutils';
import { toast } from 'react-toastify';
// import cx from 'classnames';
// import includes from 'lodash/includes';
import { compact, includes, xor } from 'lodash';
import { useQuery } from 'react-query';

import { updateGroup, createGroup, fetchBrands } from '../api';
import InputField from './form/InputField';
import PropertiesTreeWidget from './utils/PropertiesTreeWidget';
import DropdownMultiple from './utils/DropdownMultiple';

const validate = data => {
    let errors = {};

    if (!data.code) {
        errors.code = i18nMark('validation:error:mandatory:field');
    }

    if (!data.label) {
        errors.label = i18nMark('validation:error:mandatory:field');
    }

    return errors;
};

function getSelectedNodesFromGroup(group) {
    if (!group.id) {
        return [];
    }

    if (group.rules.length === 0) {
        return [];
    }

    return compact(group.rules[0].properties);
}

function BrandAllowedSelector({ value, onChange }) {
    // FIXME: traduzioni
    // FIXME: passare locale dinamicamente
    const { isLoading, data } = useQuery(
        [
            'brands',
            {
                locale: 'it_IT',
                page: 1,
                per_page: 999
            }
        ],
        fetchBrands
    );

    // console.log(value);

    const [selectedBrandsId, setSelectedBrandsId] = React.useState(value);

    // console.log(data);

    function onSelect(selectedItem) {
        const newIds = xor(selectedBrandsId, [selectedItem.id]);

        setSelectedBrandsId(newIds);
        onChange(newIds);
    }

    function onRemove(brandId) {
        const newIds = xor(selectedBrandsId, [brandId]);

        setSelectedBrandsId(newIds);
        onChange(newIds);
    }

    const items = React.useMemo(() => {
        if (!data) {
            return [];
        }

        return data.data.map(item => {
            return {
                ...item,
                value: item.id
            };
        });
    }, [data]);

    const selectedBrands = items.filter(item => {
        return includes(selectedBrandsId, item.id);
    });

    // console.log(selectedBrandsId);

    return (
        <div className="form-group">
            <label className="form-label col-3 col-sm-12">Marche disabilitate</label>
            <div className="col-9 col-sm-12">
                <DropdownMultiple
                    placeholder="Marche disabilitate"
                    isLoading={isLoading}
                    itemToString={item => {
                        if (item && item.description) {
                            return `${item.description.it_IT} (${item.code})`;
                        }

                        return '-';
                    }}
                    items={items}
                    itemValueKey="id"
                    selectedItem={selectedBrands}
                    onLoad={() => {}}
                    onChange={onSelect}
                    onClear={() => {
                        setSelectedBrandsId([]);
                        onChange([]);
                    }}
                    onRemove={onSelect}
                />
                <div>
                    {selectedBrands.map(brand => {
                        return (
                            <span
                                className="label label-secondary"
                                key={brand.id}
                                style={{ marginRight: '5px', marginTop: '2px' }}
                            >
                                {brand.description.it_IT} ({brand.code})
                                <i
                                    onClick={() => onRemove(brand.id)}
                                    className="icon icon-cross c-hand"
                                ></i>
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export class UserGroupEditModal extends Component {
    // tree = null;

    constructor(props) {
        super(props);

        this.state = {
            // isFetching: true,
            // treeData: null,
            selectedNodes: getSelectedNodesFromGroup(props.group),
            isSubmitting: false
        };

        // console.log(this.state);
    }

    onSubmit = async data => {
        const { id } = this.props.group;
        const { i18n } = this.props;

        // console.log(data);

        const isUpdating = id !== null;

        this.setState({
            isSubmitting: true
        });

        let properties = compact(this.state.selectedNodes);

        if (!properties.includes('assets')) {
            properties = properties.concat('assets');
        }

        if (!properties.includes('asset')) {
            properties = properties.concat('asset');
        }

        const payload = {
            ...data,
            rules: [
                {
                    type: 'include',
                    target: 'properties',
                    properties
                }
            ]
        };

        const xhr = isUpdating ? updateGroup(id, payload) : createGroup(payload);

        try {
            await xhr;
        } catch (err) {
            this.setState({
                isSubmitting: false
            });

            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                if (err.response.status === 412) {
                    errorMessage = i18n._('fill:required:fields');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage
            };
        }

        /*if (isUpdating) {
            this.props.updateCollectionSuccess(res);
        } else {
            this.props.fetchCollections();
        }*/

        this.setState({
            isSubmitting: false
        });

        const msg = isUpdating ? i18n._('userGroup:updated') : i18n._('userGroup:created');

        toast(msg, {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS
        });

        this.props.onClose();
    };

    render() {
        const { onClose, group } = this.props;

        const isCreating = group.id === null;

        let submit;

        return (
            <div className="modal active">
                <span className="modal-overlay" aria-label="Close" />
                <div className="modal-container">
                    <div className="modal-header">
                        <div className="modal-title h5">
                            {group.id ? `Modifica "${group.label}"` : 'Crea nuovo gruppo'}
                            <span
                                className="btn btn-clear float-right"
                                aria-label="Close"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="column col-12">
                            <Form
                                onSubmit={this.onSubmit}
                                validate={validate}
                                initialValues={{
                                    code: group.code,
                                    label: group.label,
                                    not_allowed_marca_ids: group.not_allowed_marca_ids
                                }}
                                render={({
                                    handleSubmit
                                    // submitting
                                    /*form
                                    pristine,
                                    hasValidationErrors,
                                    submitError,
                                    errors,
                                    touched,
                                    submitSucceeded,*/
                                }) => {
                                    // this.form = form;

                                    submit = handleSubmit;

                                    return (
                                        <form
                                            onSubmit={handleSubmit}
                                            className="form-horizontal"
                                            style={{
                                                position: 'relative',
                                                overflow: 'auto'
                                            }}
                                        >
                                            <Field
                                                name="code"
                                                component={InputField}
                                                placeholder={i18nMark('code')}
                                                className="form-input input-md my-2"
                                                layout="horizontal"
                                            />
                                            <Field
                                                name="label"
                                                component={InputField}
                                                placeholder={i18nMark('label')}
                                                className="form-input input-md my-2"
                                                layout="horizontal"
                                            />
                                            <Field name="not_allowed_marca_ids">
                                                {props => (
                                                    <BrandAllowedSelector
                                                        value={props.input.value}
                                                        onChange={props.input.onChange}
                                                    />
                                                )}
                                            </Field>
                                            <h6>
                                                <Trans id="rules" />
                                            </h6>
                                            {this.state.isFetching && (
                                                <div>
                                                    <Trans id="loading" />
                                                    ...
                                                </div>
                                            )}
                                            <PropertiesTreeWidget
                                                selectedNodes={this.state.selectedNodes}
                                                onChange={properties => {
                                                    this.setState({
                                                        selectedNodes: properties.selectedNodes
                                                    });
                                                }}
                                            />
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="btn btn-primary"
                            onClick={() => submit()}
                            disabled={this.state.isSubmitting}
                        >
                            {isCreating ? (
                                <Trans id="confirm:create" />
                            ) : (
                                <Trans id="confirm:update" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withI18n()(UserGroupEditModal);
